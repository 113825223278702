import { Button, Card, Checkbox, Col, Form, Row } from "antd";
import { BUTTONS } from "../../Common/Constants";

const DeviceForm = ({
  deviceData,
  devices,
  mandatory,
  onSubmit,
  formId,
  deviceImages,
  remoteConfigDevices,
}) => {
  const [form] = Form.useForm();
  const initialValues = devices?.reduce((acc, device) => {
    acc[device] = mandatory;
    return acc;
  }, {});

  const handleSubmit = (values) => {
    const selectedDevices = Object.keys(values).filter((key) => values[key]);
    const transformedValues = {
      devices: selectedDevices,
      manufacturer: deviceData?.manufacturer,
    };
    onSubmit(transformedValues, formId);
  };

  const getDeviceData = (device) => {
    const deviceList = remoteConfigDevices?.filter((i) => i?.value === device);
    return deviceList?.[0];
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleSubmit}
      style={{ margin: 0 }}
    >
      <Card
        title={deviceData?.program}
        className="mt1"
        extra={
          <Form.Item className="flex-center m0">
            <Button type="primary" htmlType="submit" size="small">
              {BUTTONS.PLACE_ORDER}
            </Button>
          </Form.Item>
        }
      >
        {devices?.map((device, index) => (
          <Row gutter={[16, 16]} key={index}>
            <Col span={24}>
              <Form.Item
                name={device}
                valuePropName="checked"
                style={{ margin: 0 }}
              >
                <Checkbox style={{ margin: 0 }} disabled={mandatory}>
                  <Row gutter={[16, 16]}>
                    <Col xs={18} sm={18} md={18} lg={6} xl={6}>
                      <img
                        src={deviceImages?.[device]}
                        alt={device}
                        width="100%"
                        style={{
                          border: "solid 1px #eee",
                          borderRadius: "4px",
                        }}
                      />
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={18}
                      xl={18}
                      className="flex-start-center"
                    >
                      <div className="display-inline">
                        <div className="bold fs16">{device}</div>
                        {getDeviceData(device)?.caption}
                      </div>
                    </Col>
                  </Row>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        ))}
      </Card>
    </Form>
  );
};

export default DeviceForm;
