import { Checkbox, Col, Divider, Form, Row, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBookingDetails,
  setTimeSlots,
} from "../../../../Reducers/bookingsSlice";
import { setProfileDetails } from "../../../../Reducers/profileSlice";
import { useApiLoader } from "../../../Common/ApiLoaderContext";
import { getApis, putApis } from "../../../Common/Apis";
import { BUTTONS, FORM_FIELDS, VALIDATIONS } from "../../../Common/Constants";
import {
  bookingsLocationsUrl,
  patientFotonaBookingsUrl,
  patientUpdateProfileUrl,
} from "../../../Common/Endpoints";
import {
  centalisedSubmitBtn,
  getRemoteConfigData,
  setUserDetails,
} from "../../../Common/Utils";

const Location = ({ setCurrentStep }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [locations, setLocations] = useState();
  const [loading, setLoading] = useState(false);
  const bookings = useSelector((state) => state?.bookings);
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const { showLoader, setShowLoader } = useApiLoader();
  const [selfPay, setSelfPay] = useState(false);
  const [insurances, setInsurances] = useState();
  const [form] = Form.useForm();
  const currentDate = moment();
  const dispatch = useDispatch();

  useEffect(() => {
    getLocations();
    getRemoteConfigData("INSURANCES").then((result) => {
      setInsurances(result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocations = async () => {
    setShowLoader(true);
    await getApis(bookingsLocationsUrl, { type: "fotona" })
      .then((response) => {
        setShowLoader(false);
        setLocations(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const onFinish = async (values) => {
    setLoading(true);
    const updatedValues = {
      ...values,
      bookingDate: currentDate.format("YYYY-MM-DD"),
      paymentStatus: values?.paymentStatus ? "Self Pay" : "Insurance",
    };
    dispatch(setBookingDetails(updatedValues));
    if (!selfPay && values?.insurance) {
      updateProfile({
        insurance: values?.insurance,
        dateOfBirth: userDetails?.dateOfBirth,
      });
    }
    await getApis(patientFotonaBookingsUrl, updatedValues)
      .then((response) => {
        setLoading(false);
        dispatch(setTimeSlots(response?.data));
        setCurrentStep(1);
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const handleCheckboxChange = (e) => {
    setSelfPay(e.target.checked);
    form.setFieldsValue({ paymentStatus: e.target.checked });

    if (e.target.checked) {
      form.setFieldsValue({
        insurance: null,
      });
    }
  };

  const updateProfile = async (data) => {
    if (userDetails?.insurance !== data?.insurance) {
      await putApis(patientUpdateProfileUrl, data)
        .then((response) => {
          setUserDetails({ ...userDetails, ...response?.data });
          dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    !showLoader && (
      <Row justify={"center"} className="mt2" data-testid="fotona-locations">
        <Col span={24} className="flex-center">
          <Form
            form={form}
            initialValues={{
              location: bookings?.details?.location,
            }}
            size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            validateMessages={{
              required: VALIDATIONS.DEFAULT.BLANK,
            }}
            style={{ width: isMobile ? "100%" : 500 }}
          >
            <Form.Item
              label={FORM_FIELDS.LOCATION}
              name="location"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Please Select"
                options={locations}
                onChange={() => {}}
              />
            </Form.Item>

            {userDetails?.insurance ? (
              <div>
                Selected Primary Insurance: <b>{userDetails?.insurance}</b>{" "}
              </div>
            ) : (
              <>
                <Divider>Select Anyone</Divider>
                <Form.Item name="paymentStatus">
                  <Checkbox onChange={handleCheckboxChange}>
                    {FORM_FIELDS.SELF_PAY}($360) - Proceed Without Insurance
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  label={FORM_FIELDS.PRIMARY_INSURANCE}
                  name="insurance"
                  size="large"
                  rules={[
                    {
                      required: !selfPay,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Please Select"
                    options={insurances}
                    readOnly={selfPay}
                    disabled={selfPay}
                  />
                </Form.Item>
              </>
            )}

            <Form.Item>
              {centalisedSubmitBtn(loading, BUTTONS.NEXT, null, 10)}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  );
};
export default Location;
