import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Steps } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../Common/Constants";
import Confirm from "./Confirm";
import Location from "./Location";
import Pay from "./Pay";
import Review from "./Review";
import TimeSlots from "./TimeSlots";

const SleepStudy = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const step = searchParams.get("step");
  const sessionId = searchParams.get("session_id");
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const [currentStep, setCurrentStep] = useState();

  useEffect(() => {
    setCurrentStep(step ? parseInt(step) : 0);
  }, [step]);

  return (
    <Row>
      <Col span={24}>
        <Button href={ROUTES.PATIENT.BOOKINGS.PATH} size="small">
          <ArrowLeftOutlined className="mr025" /> Back to Bookings
        </Button>
        <Card className="mt1">
          {isMobile ? (
            <Steps
              current={currentStep}
              direction="horizontal"
              labelPlacement="horizontal"
              style={{ flexDirection: "row", textAlign: "center" }}
              items={[
                {
                  title: "",
                },
                {
                  title: "",
                },
                {
                  title: "",
                },
                // {
                //   title: "",
                // },
                {
                  title: "",
                },
              ]}
            />
          ) : (
            <Steps
              current={currentStep}
              direction="horizontal"
              labelPlacement="vertical"
              items={[
                {
                  title: "Location",
                },
                {
                  title: "Slots",
                },
                {
                  title: "Sign",
                },
                // {
                //   title: "Pay",
                // },
                {
                  title: "Done",
                },
              ]}
            />
          )}
          <div data-testid="new-bookings">
            {currentStep === 0 && <Location setCurrentStep={setCurrentStep} />}
            {currentStep === 1 && <TimeSlots setCurrentStep={setCurrentStep} />}
            {currentStep === 2 && <Review setCurrentStep={setCurrentStep} />}
            {currentStep === 3 && <Pay setCurrentStep={setCurrentStep} />}
            {currentStep === 4 && <Confirm sessionId={sessionId} />}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default SleepStudy;
