import { EyeOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { BUTTONS, USER_TYPES } from "../Constants";

const History = ({ bookingList, userType, getBookingDetails, locationType }) => {
  const columns = [
    {
      title: "Patient Name",
      dataIndex: "fullName",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Contact Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Scheduled For",
      dataIndex: "bookingDate",
    },
    {
      title: "Return Date",
      dataIndex: "returnDate",
    },
    {
      title: "Slot",
      dataIndex: "slot",
    },
    {
      title: "Type",
      dataIndex: "bookingType",
    },
    {
      title: "Status",
      dataIndex: "bookingStatus",
      render: (_, record) =>
        record?.bookingStatus === "COMPLETED" ? (
          <span className="green bold">{record?.bookingStatus}</span>
        ) : (
          <span className="orange bold">{record?.bookingStatus}</span>
        ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div className="flex-center">
          <Button size="small" onClick={() => getBookingDetails(record)}>
            <>
              <EyeOutlined className="mr05" />
              {BUTTONS.VIEW}
            </>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div data-testid="sleepHistory">
      <Table
        bordered
        rowKey={(record) => `${record?.bookingID}-${record?.bookingDate}`}
        columns={
          userType === USER_TYPES.PATIENT
            ? columns.filter(
                (column) =>
                  !["fullName", "phoneNumber"].includes(column.dataIndex)
              )
            : locationType === "fotona"
            ? columns.filter(
                (column) =>
                  !["returnDate", "bookingType"].includes(column.dataIndex)
              )
            : userType === USER_TYPES.CLINIC_ADMIN
            ? columns.filter(
                (column) => !["bookingType"].includes(column.dataIndex)
              )
            : columns
        }
        dataSource={bookingList}
        scroll={{ x: "100%" }}
        pagination={false}
      />
    </div>
  );
};

export default History;
