import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import Loader from "../../Common/Loader";
import {
  BUTTONS,
  FORM_FIELDS,
  NOTIFICATIONS,
  ROUTES,
  VALIDATIONS,
} from "../../Common/Constants";
import { putApis } from "../../Common/Apis";
import {
  getRemoteConfigData,
  setUserDetails,
  validateDate,
} from "../../Common/Utils";
import { patientUpdateProfileUrl } from "../../Common/Endpoints";
import { useNavigate } from "react-router-dom";
import useNotify from "../../Common/Notify";
import { setProfileDetails } from "../../../Reducers/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import { MaskedInput } from "antd-mask-input";
import moment from "moment";

const EditProfile = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [loading, setLoading] = useState(false);
  const [staticDropdownData, setStaticDropdownData] = useState();
  const [insurances, setInsurances] = useState();
  const profileDetails = useSelector((state) => state?.profile?.profileDetails);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notify } = useNotify();

  useEffect(() => {
    getRemoteConfigData("DROPDOWNS").then((result) =>
      setStaticDropdownData(result)
    );
    getRemoteConfigData("INSURANCES").then((result) => {
      setInsurances(result);
    });
    form.setFieldsValue({
      ...profileDetails,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails]);

  const onFinish = async (values) => {
    setLoading(true);
    const updatedValues = {
      ...values,
      dateOfBirth: moment(new Date(values?.dateOfBirth)).format("MM-DD-YYYY"),
    };

    await putApis(patientUpdateProfileUrl, updatedValues)
      .then((response) => {
        setUserDetails({ ...userDetails, ...response?.data });
        dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        setLoading(false);
        navigate(ROUTES.PATIENT.PROFILE.PATH);
        notify(NOTIFICATIONS.SUCCESS.KEY, "Profile details has been updated");
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };
  const onFinishFailed = () => {
    setLoading(false);
  };

  const getCityFromPlace = (place) => {
    let city = "";

    place?.address_components?.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
    });

    return city;
  };

  const getStateFromPlace = (place) => {
    let state = "";

    place?.address_components?.forEach((component) => {
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      }
    });

    return state;
  };

  const getZipCodeFromPlace = (place) => {
    let zipCode = "";

    place?.address_components?.forEach((component) => {
      if (component.types.includes("postal_code")) {
        zipCode = component.long_name;
      }
    });

    return zipCode;
  };

  return (
    <Card title={ROUTES.PATIENT.EDIT_PROFILE.LABEL}>
      <div className="mt05 flex-center">
        <Row>
          <Col span={24}>
            <Form
              name="basic"
              initialValues={{
                dateOfBirth: moment(profileDetails?.dateOfBirth, [
                  "MM-DD-YYYY",
                  "YYYY-MM-DD",
                ]).format("MM/DD/YYYY"),
                addressLine1: profileDetails?.addressLine1 || "",
              }}
              form={form}
              layout="vertical"
              size="large"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              validateMessages={{
                required: VALIDATIONS.DEFAULT.BLANK,
              }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={FORM_FIELDS.FIRST_NAME}
                    name="firstName"
                    size="large"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message: VALIDATIONS.PATTERN.STRING,
                      },
                    ]}
                  >
                    <Input placeholder={FORM_FIELDS.FIRST_NAME} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={FORM_FIELDS.LAST_NAME}
                    name="lastName"
                    size="large"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message: VALIDATIONS.PATTERN.STRING,
                      },
                    ]}
                  >
                    <Input placeholder={FORM_FIELDS.LAST_NAME} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={FORM_FIELDS.EMAIL_ADDRESS}
                    name="email"
                    size="large"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: "email",
                      },
                    ]}
                  >
                    <Input placeholder={FORM_FIELDS.EMAIL_ADDRESS} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={
                      userDetails?.mrn
                        ? FORM_FIELDS.MRN
                        : `${FORM_FIELDS.MRN} (Optional)`
                    }
                    name="mrn"
                    size="large"
                    rules={[
                      {
                        required: userDetails?.mrn,
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: VALIDATIONS.PATTERN.NUMERIC,
                      },
                    ]}
                  >
                    <Input
                      placeholder={FORM_FIELDS.MRN}
                      disabled={userDetails?.mrn ? true : false}
                      readOnly={userDetails?.mrn ? true : false}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={FORM_FIELDS.GENDER}
                    name="gender"
                    size="large"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please Select"
                      options={staticDropdownData?.common?.genders}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={FORM_FIELDS.DOB}
                    name="dateOfBirth"
                    size="large"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        validator: validateDate,
                      },
                    ]}
                  >
                    <MaskedInput mask={"00/00/0000"} placeholder="MM/DD/YYYY" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item
                    label={FORM_FIELDS.PRIMARY_INSURANCE}
                    name="insurance"
                    size="large"
                  >
                    <Select
                      showSearch
                      size="large"
                      placeholder="Please Select"
                      options={insurances}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item
                    label={FORM_FIELDS.ADDRESS_LINE_1}
                    name="addressLine1"
                    size="large"
                  >
                    <Autocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_LOCATIONS_APY_KEY}
                      onPlaceSelected={(place) => {
                        form.setFieldsValue({
                          addressLine1: place.formatted_address,
                          city: getCityFromPlace(place),
                          state: getStateFromPlace(place),
                          zipcode: getZipCodeFromPlace(place),
                        });
                      }}
                      onBlur={() => {
                        form.setFieldsValue({
                          addressLine1: "",
                          city: "",
                          state: "",
                          zipcode: "",
                        });
                      }}
                      onChange={(value) => {
                        if (!value) {
                          form.setFieldsValue({
                            addressLine1: "",
                            city: "",
                            state: "",
                            zipcode: "",
                          });
                        }
                      }}
                      placeholder={FORM_FIELDS.ADDRESS_LINE_1}
                      className="ant-autocomplete"
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "us" },
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item
                    label={FORM_FIELDS.ADDRESS_LINE_2}
                    name="addressLine2"
                    size="large"
                  >
                    <Input placeholder={FORM_FIELDS.ADDRESS_LINE_2} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    label={FORM_FIELDS.CITY}
                    name="city"
                    size="large"
                    rules={[
                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message: VALIDATIONS.PATTERN.STRING,
                      },
                    ]}
                  >
                    <Input placeholder={FORM_FIELDS.CITY} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    label={FORM_FIELDS.STATE}
                    name="state"
                    size="large"
                    rules={[
                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message: VALIDATIONS.PATTERN.STRING,
                      },
                    ]}
                  >
                    <Input placeholder={FORM_FIELDS.STATE} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    label={FORM_FIELDS.ZIPCODE}
                    name="zipcode"
                    size="large"
                    rules={[
                      {
                        pattern: /^[0-9]+$/,
                        message: VALIDATIONS.PATTERN.NUMERIC,
                      },
                    ]}
                  >
                    <Input placeholder={FORM_FIELDS.ZIPCODE} />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="flex-center">
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item className="mt2">
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      disabled={loading}
                    >
                      <Loader loading={loading} text={BUTTONS.SAVE} />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default EditProfile;
