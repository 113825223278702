import { Col, Divider, Radio, Row } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getApis, postApis, putApis } from "../../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, ROUTES } from "../../../Common/Constants";
import { patientPaymentsUrl } from "../../../Common/Endpoints";
import useNotify from "../../../Common/Notify";
import {
  capitaliseWord,
  centalisedSubmitBtn,
  getBaseUrl,
} from "../../../Common/Utils";

const Pay = ({ setCurrentStep }) => {
  const [cardDetails, setCardDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState();
  const bookingDetails = useSelector((state) => state?.bookings?.details);
  const { notify } = useNotify();

  const getCards = async () => {
    await getApis(patientPaymentsUrl)
      .then((response) => {
        setCardDetails(response?.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getCards();
  }, []);

  const onSubmitHandler = () => {
    setLoading(true);
    if (cardDetails?.length === 0) {
      freshPay();
    } else {
      if (!selectedCard) {
        notify(NOTIFICATIONS.ERROR.KEY, "Please select a Card");
      } else {
        updatePay();
      }
    }
  };

  const freshPay = async () => {
    await postApis(patientPaymentsUrl, {
      redirectUrl: `${getBaseUrl()}${ROUTES.PATIENT.SLEEP_STUDY.PATH}?step=4&`,
      cancelUrl: `${getBaseUrl()}${ROUTES.PATIENT.SLEEP_STUDY.PATH}`,
      paymentType: "bookings",
      checkoutType: "saveCard",
    })
      .then((response) => {
        setLoading(false);
        window.location.assign(response?.data?.paymentUrl);
      })
      .catch(() => setLoading(false));
  };

  const updatePay = async () => {
    await putApis(patientPaymentsUrl, {
      paymentMethodId: selectedCard,
      bookingDate: bookingDetails?.bookingDate,
    })
      .then(() => {
        setLoading(false);
        setCurrentStep(4);
      })
      .catch(() => setLoading(false));
  };

  const onCardSelect = (e) => {
    setSelectedCard(e.target.value);
  };

  return (
    <Row data-testid="bookings-pay" justify={"center"} className="mt2">
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <div>
          {cardDetails?.length === 0 ? (
            <p>
              In the next step, you will be redirected to a third-party screen
              where you need to enter your card details. Your card information
              will be securely saved for deposit purposes and as a security
              measure.
            </p>
          ) : (
            <div>
              <p>Please choose the card below</p>
              <Radio.Group onChange={onCardSelect}>
                {cardDetails?.map((item, index) => {
                  return (
                    <div key={index} className="mt05 grey-fill pd1">
                      <Radio value={item?.id}>
                        <div className="blue bold fs16">
                          {capitaliseWord(item?.brand)}
                        </div>
                        <div>
                          <span className="grey">Card: </span> ...{item?.last4}{" "}
                          <span className="ml1 grey">Expiry:</span>{" "}
                          {item?.expMonth}/{item?.expYear}
                        </div>
                      </Radio>
                    </div>
                  );
                })}
              </Radio.Group>
            </div>
          )}

          <div className="bold mt2">Important Points:</div>
          <ul>
            <li>
              You will only be charged for late fees or device damage at the
              time of your return.
            </li>
            <li>Any charges will be made only after notifying you.</li>
            <li>No money will be charged from your card at this stage.</li>
          </ul>
        </div>
        <Divider style={{ margin: "2rem 0 1.5rem 0" }} />
        {centalisedSubmitBtn(loading, BUTTONS.NEXT, onSubmitHandler)}
      </Col>
    </Row>
  );
};

export default Pay;
