import { Card, Col } from "antd";
import LOGO from "../../Assets/images/Tinnitus/logo.png";

const TinCards = ({ minHeight, imgSrc, title, description, borderColor }) => {
  return (
    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
      <Card
        styles={{
          body: {
            minHeight: minHeight,
            padding: "1rem",
            border: `solid 2px ${borderColor}`,
          },
        }}
      >
        <img alt="example" src={imgSrc} width="100%" className="mb05" />
        <div className={"blue fs24 flex-center bold mt05"}>
          <img alt="logo" src={LOGO} className="mr05" />
          {title}
          <img alt="logo" src={LOGO} className="ml05" />
        </div>
        <div className="left-aligned-pgp mt1">{description}</div>
      </Card>
    </Col>
  );
};

export default TinCards;
