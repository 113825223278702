import { DoubleRightOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBookingDetails } from "../../../Reducers/bookingsSlice";
import { BUTTONS, ROUTES } from "../Constants";
import Loader from "../Loader";
import { displayOnlyDate } from "../Utils";

const Pending = ({ bookingList }) => {
  const [loading, setLoading] = useState(false);
  const [loadingRowId, setLoadingRowId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickHandler = async (record) => {
    setLoadingRowId(record?.bookingID);
    setLoading(true);
    dispatch(setBookingDetails(null));
    dispatch(
      setBookingDetails({
        oldBookingDate: record?.bookingDate,
        bookingDate: record?.bookingDate,
        location: record?.location,
        returnDate: record?.returnDate,
        slot: record?.slot,
        days: record?.days,
        paymentStatus: record?.paymentStatus,
      })
    );
    navigate(`${ROUTES.PATIENT.SLEEP_STUDY.PATH}?step=1`);
    setLoading(false);
    setLoadingRowId(null);
  };

  const columns = [
    {
      title: "Scheduled For",
      dataIndex: "bookingDate",
      render: (_, record) => displayOnlyDate(record?.bookingDate),
    },
    {
      title: "Return Date",
      dataIndex: "returnDate",
      render: (_, record) => displayOnlyDate(record?.returnDate),
    },
    {
      title: "Alloted Slot",
      dataIndex: "slot",
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div className="flex-center">
          <Button
            className="mr05 blue-btn"
            size="small"
            disabled={loadingRowId === record.bookingID}
            onClick={() => onClickHandler(record)}
          >
            <Loader
              loading={loading && loadingRowId === record.bookingID}
              text={
                <>
                  <DoubleRightOutlined /> {BUTTONS.CONTINUE}
                </>
              }
            />
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div data-testid="sleepPending">
      <Table
        bordered
        rowKey={(record) => `${record?.bookingDate}-${record?.slot}`}
        columns={columns}
        dataSource={bookingList}
        scroll={{ x: "100%" }}
        pagination={false}
      />
    </div>
  );
};

export default Pending;
