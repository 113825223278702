import { ROUTES, USER_TYPES } from "./Constants";
import { WarningOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Badge, Col, Row } from "antd";
import {
  clinicAdminDynamicSiderItem,
  generateMenuItems,
  patientDynamicSiderItem,
} from "./Utils";

export const patientMenuLinks = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const defaultMenus = generateMenuItems([
    ROUTES.PATIENT.CONNECTED_DEVICES,
    ROUTES.PATIENT.BOOKINGS,
    ROUTES.PATIENT.ORDERS,
    // ROUTES.PATIENT.PAYMENTS,
    ROUTES.PATIENT.PROGRAMS,
    ROUTES.PATIENT.PROFILE,
    ROUTES.DIVIDER,
    ROUTES.LOGOUT,
  ]);
  return userDetails?.dashboard
    ? [
        ...patientDynamicSiderItem(userDetails?.dashboard).filter(
          (item) => item !== null
        ),
        ...defaultMenus,
      ]
    : defaultMenus;
};

export const clinicAdminMenuLinks = () => {
  const clinicAdminMenus = JSON.parse(localStorage.getItem("clinicAdminMenus"));
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  let defaultMenus = generateMenuItems([
    ROUTES.CLINIC_ADMIN.DASHBOARD,
    ROUTES.CLINIC_ADMIN.PROVIDERS,
  ]);

  let endMenuItems = generateMenuItems([
    ROUTES.CLINIC_ADMIN.COMPLETED_PROGRAMS,
    ROUTES.CLINIC_ADMIN.SLEEP,
    ROUTES.CLINIC_ADMIN.FOTONA,
    ROUTES.DIVIDER,
    ROUTES.LOGOUT,
  ]);

  if (userDetails?.clinic !== "SFOTO") {
    endMenuItems = endMenuItems?.filter(
      (item) => item?.key !== ROUTES.CLINIC_ADMIN.SLEEP.PATH
    );
  }

  return [
    ...defaultMenus,
    ...clinicAdminDynamicSiderItem(clinicAdminMenus),
    ...endMenuItems,
  ];
};

export const providerMenuLinks = (notification) => {
  const VITAL_ALERTS = {
    PATH: ROUTES.PROVIDER.VITAL_ALERTS.PATH,
    LABEL: (
      <Row>
        <Col span={16}>{ROUTES.PROVIDER.VITAL_ALERTS.LABEL}</Col>
        <Col span={6}>
          <Badge size="default" count={notification?.vitalCount} />
        </Col>
      </Row>
    ),
    ICON: <WarningOutlined className="menu-icon" />,
  };
  const OTHER_ALERTS = {
    PATH: ROUTES.PROVIDER.OTHER_ALERTS.PATH,
    LABEL: (
      <Row>
        <Col span={16}>{ROUTES.PROVIDER.OTHER_ALERTS.LABEL}</Col>
        <Col span={6}>
          <Badge size="default" count={notification?.otherCount} />
        </Col>
      </Row>
    ),
    ICON: <ExclamationCircleOutlined className="menu-icon" />,
  };
  let items = [];

  items.push(
    ROUTES.PROVIDER.DASHBOARD,
    ROUTES.PROVIDER.ACTIVE_PATIENTS,
    ROUTES.PROVIDER.COMPLETED_PROGRAMS,
    VITAL_ALERTS,
    OTHER_ALERTS
  );

  items.push(
    ROUTES.PROVIDER.VIALS,
    ROUTES.PROVIDER.CLAIMS,
    ROUTES.PROVIDER.PROFILE,
    ROUTES.DIVIDER,
    ROUTES.LOGOUT
  );

  return generateMenuItems(items);
};

export const sidebarItems = (notification) => {
  const userType = localStorage.getItem("userType");

  if (userType === USER_TYPES.PATIENT) {
    return patientMenuLinks().filter(
      (item) => ![ROUTES.DIVIDER.TYPE, ROUTES.LOGOUT.PATH].includes(item.key)
    );
  } else if (userType === USER_TYPES.PROVIDER) {
    return providerMenuLinks(notification).filter(
      (item) => ![ROUTES.DIVIDER.TYPE, ROUTES.LOGOUT.PATH].includes(item.key)
    );
  } else if (userType === USER_TYPES.CLINIC_ADMIN) {
    return clinicAdminMenuLinks().filter(
      (item) => ![ROUTES.DIVIDER.TYPE, ROUTES.LOGOUT.PATH].includes(item.key)
    );
  }
};

export const desktopMenuItems = (notification) => {
  const userType = localStorage.getItem("userType");

  if (userType === USER_TYPES.PATIENT) {
    return patientMenuLinks().filter((item) =>
      [ROUTES.LOGOUT.PATH].includes(item.key)
    );
  } else if (userType === USER_TYPES.PROVIDER) {
    return providerMenuLinks(notification).filter((item) =>
      [ROUTES.LOGOUT.PATH].includes(item.key)
    );
  } else if (userType === USER_TYPES.CLINIC_ADMIN) {
    return clinicAdminMenuLinks().filter((item) =>
      [ROUTES.LOGOUT.PATH].includes(item.key)
    );
  }
};

export const mobileMenuItems = (notification) => {
  const userType = localStorage.getItem("userType");

  if (userType === USER_TYPES.PATIENT) {
    return patientMenuLinks();
  } else if (userType === USER_TYPES.PROVIDER) {
    return providerMenuLinks(notification);
  } else if (userType === USER_TYPES.CLINIC_ADMIN) {
    return clinicAdminMenuLinks();
  }
};
