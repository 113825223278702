import {
  CalendarOutlined,
  CreditCardOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Empty, Row } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis, postApis, putApis } from "../../Common/Apis";
import { BUTTONS, ROUTES } from "../../Common/Constants";
import { patientPaymentsUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import { capitaliseWord, getBaseUrl } from "../../Common/Utils";

const Payments = () => {
  const [cardDetails, setCardDetails] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");
  const navigate = useNavigate();

  const getCards = async () => {
    setShowLoader(true);
    await getApis(patientPaymentsUrl)
      .then((response) => {
        setShowLoader(false);
        setCardDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const updatePayment = async () => {
    await putApis(patientPaymentsUrl, {
      chekoutOutSessionId: sessionId,
    })
      .then(() => navigate(ROUTES.PATIENT.PAYMENTS.PATH))
      .catch(() => {});
  };

  useEffect(() => {
    getCards();
    if (sessionId) {
      updatePayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewCard = async () => {
    setLoading(true);
    await postApis(patientPaymentsUrl, {
      redirectUrl: `${getBaseUrl()}${ROUTES.PATIENT.PAYMENTS.PATH}?`,
      cancelUrl: `${getBaseUrl()}${ROUTES.PATIENT.PAYMENTS.PATH}`,
      paymentType: "newCard",
      checkoutType: "saveCard",
    })
      .then((response) => {
        setLoading(false);
        window.location.assign(response?.data?.paymentUrl);
      })
      .catch(() => setLoading(false));
  };

  return (
    !showLoader && (
      <Card
        title="Cards"
        extra={
          <Button
            type="primary"
            disabled={loading}
            onClick={() => addNewCard()}
          >
            <Loader loading={loading} text={BUTTONS.ADD} />
          </Button>
        }
      >
        {cardDetails?.length > 0 ? (
          <Row gutter={[16, 16]}>
            {cardDetails?.map((item, index) => {
              return (
                <Col key={index} span={12}>
                  <div className="mt05 grey-fill box-shadow pd1">
                    <div className="blue bold fs16">
                      {capitaliseWord(item?.brand)}
                    </div>
                    <div className="mt105">
                      <CreditCardOutlined className="mr05" />
                      ...{item?.last4}{" "}
                      <span className="ml1">
                        <CalendarOutlined className="mr05" />
                      </span>{" "}
                      {item?.expMonth}/{item?.expYear}
                      {item?.email && (
                        <>
                          <span className="ml1">
                            <MailOutlined className="mr05" />
                          </span>{" "}
                          {item?.email}
                        </>
                      )}
                    </div>
                    <div></div>
                  </div>
                </Col>
              );
            })}
          </Row>
        ) : (
          <Empty />
        )}
      </Card>
    )
  );
};

export default Payments;
