import ImageProgressBar from "./ImageProgressBar";
import { Card, Col, Flex } from "antd";

const Wellness = ({ apiData, barImage, arrowImage }) => {
  return (
    <Col xs={24} sm={12} md={10} lg={6} xl={6}>
      <Card
        styles={{ body: { padding: "1rem 0.5rem" } }}
        className="text-center more-shadow"
      >
        <div className="bold fs16 mb105">{apiData?.title}</div>
        <div
          className="flex-center"
          style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
        >
          <ImageProgressBar
            percent={apiData?.value}
            size={120}
            imageSrc={barImage}
            strokeColor={apiData?.strokeColor}
          />
        </div>
        <div className="bold fs22 mt105">{apiData?.value}%</div>
        <div className="mt1 grey flex-center fs12">
          <Flex wrap="wrap" justify={"start"}>
            <img src={arrowImage} alt="UP/DOWN" width="30" className="mr05" />
            <div>{apiData?.delta}% from Yesterday</div>
          </Flex>
        </div>
      </Card>
    </Col>
  );
};

export default Wellness;
