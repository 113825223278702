import { Button, Col, Form, Input, Row, Select } from "antd";
import { BUTTONS, FORM_FIELDS, VALIDATIONS } from "../../Common/Constants";
import Loader from "../../Common/Loader";
import Popup from "../../Common/Popup";
const { Option } = Select;

const colors = [
  { name: "White", value: "#FFF5E1" },
  { name: "White Yellow", value: "#FFECB3" },
  { name: "Yellow", value: "#FFD700" },
  { name: "Brown", value: "#8B4513" },
  { name: "Dark Brown", value: "#5D4037" },
  { name: "Black", value: "#3E2723" },
  { name: "Other", value: "#D2B48C" },
];

const ConnectRing = ({
  loading,
  onFinish,
  onFinishFailed,
  userDetails,
  isRingModalOpen,
  handleRingModelCancel,
  form
}) => {

  return (
    <Popup
      title={"Connect To Ring"}
      open={isRingModalOpen}
      cancel={handleRingModelCancel}
      footer={null}
      width={600}
    >
      <Form
        initialValues={{
          feet: userDetails?.feet,
          inches: userDetails?.inches,
          lbsWeight: userDetails?.lbsWeight,
          skinColor: userDetails?.skinColor,
          age: userDetails?.age,
          gender: userDetails?.gender,
        }}
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Height in Feet"
                  name="feet"
                  rules={[
                    {
                      required: true,
                      message: VALIDATIONS.DEFAULT.BLANK,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Feet"
                    min={1}
                    step={1}
                    max={12}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Height in Inches"
                  name="inches"
                  rules={[
                    {
                      required: true,
                      message: VALIDATIONS.DEFAULT.BLANK,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Inches"
                    min={1}
                    step={1}
                    max={11}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label={FORM_FIELDS.LBS_WEIGHT}
              name="lbsWeight"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="number" placeholder="150" min={1} step={1} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label={FORM_FIELDS.SKIN_COLOR}
              name="skinColor"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder={FORM_FIELDS.SKIN_COLOR}
                className="picker-align"
              >
                {colors.map((color, index) => (
                  <Option key={index} value={color.name}>
                    <span
                      style={{
                        backgroundColor: color.value,
                        width: "38px",
                        height: "22px",
                        display: "inline-block",
                        marginRight: "10px",
                        border: "solid 1px #ccc",
                        borderRadius: "1rem",
                      }}
                    ></span>
                    {color.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.AGE}
              name="age"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={FORM_FIELDS.AGE}
                disabled={true}
                readOnly={true}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.GENDER}
              name="gender"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={FORM_FIELDS.GENDER}
                disabled={true}
                readOnly={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="flex-center mt1">
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Popup>
  );
};

export default ConnectRing;
