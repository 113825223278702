import {
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  DollarOutlined,
  SignatureOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { displayOnlyDate } from "../../../Common/Utils";

export const displayConfirmInfo = (details) => {
  return [
    {
      icon: CalendarOutlined,
      label: "Scheduled For",
      value: `${displayOnlyDate(details?.bookingDate)} (${details?.slot})`,
    },
    {
      icon: ClockCircleOutlined,
      label: "Number of Days",
      value: details?.days,
    },
    {
      icon: EnvironmentOutlined,
      label: "Location",
      value: details?.location,
    },
    {
      icon: CalendarOutlined,
      label: "Return Date",
      value: displayOnlyDate(details?.returnDate),
    },
    {
      icon: SignatureOutlined,
      label: "Document Signed",
      value: details?.sign ? "Yes" : "No",
    },
    {
      icon: DollarOutlined,
      label: "Payment Method",
      value: details?.paymentStatus,
    },
  ];
};

export const displayReviewInfo = (details) => {
  return [
    {
      icon: CalendarOutlined,
      label: "Scheduled For",
      value: `${moment(details?.bookingDate).format("MM-DD-YYYY")} (${
        details?.slot
      })`,
    },
    {
      icon: ClockCircleOutlined,
      label: "Number of Days",
      value: details?.days,
    },
    {
      icon: EnvironmentOutlined,
      label: "Location",
      value: details?.location,
    },
    {
      icon: CalendarOutlined,
      label: "Return Date",
      value: moment(details?.returnDate).format("MM-DD-YYYY"),
    },
    {
      icon: DollarOutlined,
      label: "Payment Method",
      value: details?.paymentStatus,
    },
  ];
};
