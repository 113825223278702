import {
  Card,
  Col,
  Divider,
  Empty,
  Flex,
  Progress,
  Row,
  Segmented,
  Select,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { getApis } from "../../Common/Apis";
import { patientRingUrl, patientRingVitalsUrl } from "../../Common/Endpoints";
import RING from "../../../Assets/images/Devices/sleepRing.png";
import HR from "../../../Assets/images/Ring/hr.png";
import HRV from "../../../Assets/images/Ring/hrv.png";
import SPO2 from "../../../Assets/images/Ring/spo2.png";
import RESPIRATORY from "../../../Assets/images/Ring/respiratory.png";
import STEPS from "../../../Assets/images/Ring/steps.png";
import CALORIES from "../../../Assets/images/Ring/calories.png";
import DISTANCE from "../../../Assets/images/Ring/distance.png";
import BATTERY from "../../../Assets/images/Ring/battery.png";
import DEEP_SLEEP from "../../../Assets/images/Ring/deepSleep.png";
import REM_SLEEP from "../../../Assets/images/Ring/remSleep.png";
import SLEEP_DURATION from "../../../Assets/images/Ring/sleepTime.png";
import LIGHT_SLEEP from "../../../Assets/images/Ring/lightSleep.png";
import {
  activateRing,
  displayOnlyTime,
  getPageHeader,
  getRemoteConfigData,
} from "../../Common/Utils";
import AreaChart from "./RingVitals/AreaChart";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BarChart from "./RingVitals/BarChart";

const Ring = ({ setShowLoader, showLoader }) => {
  const [ringData, setRingData] = useState();
  const [vitalsData, setVitalsData] = useState();
  const [filterType, setFilterType] = useState("day");
  const [staticDropdownData, setStaticDropdownData] = useState();
  const [vitalType, setVitalType] = useState("heartRate");
  const [graphColor, setGraphColor] = useState();
  const [graphLoader, setGraphLoader] = useState();
  const [vitalHeader, setVitalHeader] = useState();
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const deviceBatteryDetails = JSON.parse(
    localStorage.getItem("deviceBatteryDetails")
  );
  const deviceConnectionDetails = JSON.parse(
    localStorage.getItem("deviceConnectionDetails")
  );
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const getRingData = async () => {
    setShowLoader(true);
    await getApis(patientRingUrl, {
      currentTime: new Date().getTime(),
      timeZone: timezone,
    })
      .then((response) => {
        setShowLoader(false);
        setRingData(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const getRingVitalsData = async () => {
    setGraphLoader(true);
    await getApis(patientRingVitalsUrl, {
      filterType: filterType.toLowerCase(),
      vitalType: vitalType,
      timeZone: timezone,
    })
      .then((response) => {
        setGraphLoader(false);
        setVitalsData(response?.data);
      })
      .catch(() => {
        setGraphLoader(false);
        setVitalsData();
      });
  };

  useEffect(() => {
    getRemoteConfigData("DROPDOWNS").then((result) => {
      setStaticDropdownData(result);
      const selectedOption = result?.patients?.ring?.find(
        (option) => option.value === vitalType
      );
      setGraphColor(selectedOption?.color);
      setVitalHeader(
        ["step", "sleep"].includes(selectedOption?.value)
          ? selectedOption?.label
          : `${selectedOption?.label}  (${
              basicDataMappings[selectedOption?.value]?.units ||
              stepDataMappings[selectedOption?.value]?.units
            })`
      );
    });
    getRingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRingVitalsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, vitalType]);

  const basicDataMappings = {
    heartRate: { label: "Heart Rate", image: HR, units: "bpm" },
    hrv: { label: "HRV", image: HRV, units: "ms" },
    respirationRate: {
      label: "Respiration Rate",
      image: RESPIRATORY,
      units: "breaths/min",
    },
    bloodOxygen: { label: "SpO2", image: SPO2, units: "%" },
  };

  const stepDataMappings = {
    calories: { label: "Calories", image: CALORIES, units: "kcal" },
    steps: { label: "Steps", image: STEPS, units: "" },
    step: { label: "Steps", image: STEPS, units: "" },
    distance: { label: "Distance", image: DISTANCE, units: "mi" },
  };

  const sleepImageMappings = {
    sleepDuration: SLEEP_DURATION,
    deepSleep: DEEP_SLEEP,
    remSleep: REM_SLEEP,
    lightSleep: LIGHT_SLEEP,
  };

  const onVitalChange = (value) => {
    setVitalType(value);
    const selectedOption = staticDropdownData?.patients?.ring?.find(
      (option) => option.value === value
    );
    setGraphColor(selectedOption?.color);
    setVitalHeader(
      ["step", "sleep"].includes(selectedOption?.value)
        ? selectedOption?.label
        : `${selectedOption?.label}  (${
            basicDataMappings[selectedOption?.value]?.units ||
            stepDataMappings[selectedOption?.value]?.units
          })`
    );
  };

  const renderBasicCards = (index, span, key, data) => {
    return (
      basicDataMappings[key] && (
        <Col key={index} xs={24} sm={12} md={12} lg={8} xl={span}>
          <Card
            className="box-more-shadow"
            styles={{
              body: {
                padding: "1rem",
              },
            }}
          >
            <Row justify={"space-between"}>
              <Col span={16}>
                <div className="fs16 bold grey">
                  {basicDataMappings[key]?.label}
                </div>
                <div className="mt1">
                  <Flex wrap="wrap" gap="small" className="flex-baseline">
                    <div className="fs32 bold">{data[key]}</div>
                    <div>{basicDataMappings[key]?.units}</div>
                  </Flex>
                </div>
              </Col>
              <Col span={8}>
                <img
                  src={basicDataMappings[key]?.image}
                  alt={key}
                  width="100%"
                />
                <div className="grey fs13 text-center">
                  {displayOnlyTime(data?.vitalCollectedTimestamp)}
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      )
    );
  };

  const renderStepsCards = (index, span, key, data) => {
    return (
      stepDataMappings[key] && (
        <Col key={index} xs={24} sm={12} md={12} lg={8} xl={span}>
          <Card
            className="box-more-shadow"
            styles={{
              body: {
                padding: "1rem",
              },
            }}
          >
            <Row justify={"space-between"}>
              <Col span={16}>
                <div className="fs16 bold grey">
                  {stepDataMappings[key]?.label}
                </div>
                <div className="mt2">
                  <Flex wrap="wrap" gap="small" className="flex-baseline">
                    <div className="fs30 bold">{data[key]}</div>
                    <div>{stepDataMappings[key]?.units}</div>
                  </Flex>
                </div>
              </Col>
              <Col span={8}>
                <img
                  src={stepDataMappings[key]?.image}
                  alt={key}
                  width="100%"
                />
              </Col>
            </Row>
          </Card>
        </Col>
      )
    );
  };

  return (
    !showLoader && (
      <>
        {ringData ? (
          <>
            <Card className="blue-card">
              <Row justify={"space-between"} gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                  <div>
                    <div className="fs24 bold">SAiWELL R8 Ring</div>
                    <div className="mt05">
                      It is a proprietary smart health ring that is equipped
                      with multiple biometric sensors that continuously monitor
                      vital signs, including heart rate, sleep patterns, and
                      blood oxygen levels. Its advanced technology provides
                      real-time health insights, helping users optimize their
                      well-being.
                    </div>
                  </div>
                </Col>
                {window?.webkit?.messageHandlers?.uidMessageHandler ? (
                  <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Card
                      styles={{
                        body: {
                          padding: "1rem",
                        },
                      }}
                    >
                      <Row justify={"space-between"}>
                        <Col span={16}>
                          <div className="fs16 bold grey">Battery Status</div>
                          {deviceConnectionDetails && (
                            <div className="mt105 green bold flex-start-center">
                              <img
                                src={BATTERY}
                                alt="Battery"
                                width="15%"
                                className="mr05"
                              />
                              {deviceConnectionDetails?.SaiwellRing ? (
                                "Connected"
                              ) : (
                                <Link
                                  key="activate"
                                  onClick={() => activateRing()}
                                >
                                  Connect
                                </Link>
                              )}
                            </div>
                          )}
                        </Col>
                        <Col span={8}>
                          <Progress
                            type="circle"
                            percent={deviceBatteryDetails?.SaiwellRing || 0}
                            size={90}
                            strokeColor={
                              deviceConnectionDetails?.SaiwellRing
                                ? "#00A73E"
                                : "#ccc"
                            }
                            strokeWidth={14}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ) : (
                  <Col xs={0} sm={0} md={0} lg={3} xl={3}>
                    <img src={RING} alt="summary" width="100%" />
                  </Col>
                )}
              </Row>
            </Card>

            <Row gutter={[16, 16]} className="mt1" justify="start">
              {Object.keys(ringData?.stepData)?.map((key, index) => {
                return renderStepsCards(index, 8, key, ringData?.stepData);
              })}
            </Row>
            <Row gutter={[16, 16]} className="mt2" justify="start">
              {Object.keys(ringData?.basicData)?.map((key, index) => {
                return (
                  key !== "toString" &&
                  renderBasicCards(index, 6, key, ringData?.basicData)
                );
              })}
            </Row>
            <div className="mt2 bold fs28">Sleep</div>
            <Divider style={{ margin: "0.5rem 0 1rem 0" }} />
            <Row gutter={[16, 32]} className="mt2" justify="space-between">
              {ringData?.sleepData?.map((item) => {
                return Object.keys(item).map((key) => {
                  return (
                    <Col key={key} xs={24} sm={12} md={12} lg={12} xl={6}>
                      <Card
                        className="box-more-shadow"
                        styles={{
                          body: {
                            padding: "1rem",
                          },
                        }}
                      >
                        <Row key={key} justify={"space-between"}>
                          <Col span={16}>
                            <div className="fs16 bold grey">
                              {getPageHeader(key)}
                            </div>
                            <div className="mt1">
                              <Flex
                                wrap="wrap"
                                gap="small"
                                className="flex-baseline"
                              >
                                <div className="fs30 bold">
                                  {item[key].hour}
                                </div>
                                <div>hrs</div>
                                <div className="fs30 bold">
                                  {item[key].mins}
                                </div>
                                <div>mins</div>
                              </Flex>
                            </div>
                          </Col>
                          <Col span={8}>
                            <img
                              src={sleepImageMappings?.[key]}
                              alt={sleepImageMappings?.[key]}
                              width="100%"
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                });
              })}
            </Row>

            <Card
              title={
                <Row
                  gutter={[16, 16]}
                  justify={"space-between"}
                  className="pdtb1"
                >
                  <Col xs={24} sm={24} md={24} lg={10} xl={6}>
                    <Select
                      size="large"
                      value={vitalType}
                      placeholder="Select Status"
                      options={staticDropdownData?.patients?.ring}
                      className="full-width"
                      onChange={onVitalChange}
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={10}
                    xl={8}
                    className="flex-center"
                  >
                    <Segmented
                      options={[
                        { label: "Daily", value: "day" },
                        { label: "Weekly", value: "week" },
                        { label: "Monthly", value: "month" },
                      ]}
                      value={filterType}
                      onChange={(value) => {
                        setFilterType(value);
                      }}
                      size="large"
                    />
                  </Col>
                </Row>
              }
              className="mt2"
            >
              {graphLoader ? (
                <Spin className="flex-center mt1" size="large" />
              ) : vitalsData?.length > 0 ? (
                filterType === "day" &&
                !["step", "calories", "distance", "sleep"].includes(
                  vitalType
                ) ? (
                  <AreaChart
                    isMobile={isMobile}
                    vitalHeader={vitalHeader}
                    graphColor={graphColor}
                    vitalsData={vitalsData}
                    filterType={filterType}
                  />
                ) : (
                  <BarChart
                    isMobile={isMobile}
                    vitalHeader={vitalHeader}
                    graphColor={graphColor}
                    vitalsData={vitalsData}
                    filterType={filterType}
                    vitalType={vitalType}
                  />
                )
              ) : (
                <Empty />
              )}
            </Card>
          </>
        ) : (
          <Card>
            <Empty />
          </Card>
        )}
      </>
    )
  );
};

export default Ring;
