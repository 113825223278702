import { Checkbox, Col, Divider, Form, Radio, Row, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBookingDetails,
  setTimeSlots,
} from "../../../../Reducers/bookingsSlice";
import { setProfileDetails } from "../../../../Reducers/profileSlice";
import { getApis, putApis } from "../../../Common/Apis";
import { BUTTONS, FORM_FIELDS, VALIDATIONS } from "../../../Common/Constants";
import {
  bookingsLocationsUrl,
  patientBookingsUrl,
  patientUpdateProfileUrl,
} from "../../../Common/Endpoints";
import {
  centalisedSubmitBtn,
  getOnlyWeekDays,
  getRemoteConfigData,
  setUserDetails,
} from "../../../Common/Utils";

const Location = ({ setCurrentStep }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [locations, setLocations] = useState();
  const [insurances, setInsurances] = useState();
  const [loading, setLoading] = useState(false);
  const bookings = useSelector((state) => state?.bookings);
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const [selectedWeeks, setSelectedWeeks] = useState(
    userDetails?.weeks || false
  );
  const [selfPay, setSelfPay] = useState(false);
  const [form] = Form.useForm();
  const currentDate = moment();
  const dispatch = useDispatch();

  useEffect(() => {
    getRemoteConfigData("INSURANCES").then((result) => {
      setInsurances(result);
    });
    getLocations();
  }, []);

  const getLocations = async () => {
    await getApis(bookingsLocationsUrl, { type: "sleep" })
      .then((response) => {
        setLocations(response?.data);
      })
      .catch(() => {});
  };

  const handleInsuranceChange = (value) => {
    const selectedOption = insurances.find((option) => option.value === value);
    if (selectedOption) {
      setSelectedWeeks(selectedOption.weeks);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const resultDate = getOnlyWeekDays(currentDate, values?.days);
    const updatedValues = {
      ...values,
      weeks: selectedWeeks,
      paymentStatus: values?.paymentStatus ? "Self Pay" : "Insurance",
      bookingDate: currentDate.format("YYYY-MM-DD"),
      returnDate: resultDate.format("YYYY-MM-DD"),
    };
    dispatch(setBookingDetails(updatedValues));
    if (!selfPay && values?.insurance) {
      updateProfile({
        weeks: selectedWeeks,
        insurance: values?.insurance,
        dateOfBirth: userDetails?.dateOfBirth,
      });
    }
    await getApis(patientBookingsUrl, updatedValues)
      .then((response) => {
        setLoading(false);
        dispatch(setTimeSlots(response?.data));
        setCurrentStep(1);
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const updateProfile = async (data) => {
    if (userDetails?.insurance !== data?.insurance) {
      await putApis(patientUpdateProfileUrl, data)
        .then((response) => {
          setUserDetails({ ...userDetails, ...response?.data });
          dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleCheckboxChange = (e) => {
    setSelfPay(e.target.checked);
    form.setFieldsValue({ paymentStatus: e.target.checked });

    if (e.target.checked) {
      form.setFieldsValue({
        insurance: null,
      });
    }
  };

  return (
    <Row justify={"center"} className="mt2">
      <Col span={24} className="flex-center">
        <Form
          form={form}
          initialValues={{
            location: bookings?.details?.location,
            insurance: bookings?.details?.insurance,
            days: bookings?.details?.days || 2,
            dateOfBirth: userDetails?.dateOfBirth,
          }}
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          validateMessages={{
            required: VALIDATIONS.DEFAULT.BLANK,
          }}
          style={{ width: isMobile ? "100%" : 500 }}
        >
          <Form.Item
            label={FORM_FIELDS.LOCATION}
            name="location"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              size="large"
              placeholder="Please Select"
              options={locations}
              onChange={() => {}}
            />
          </Form.Item>
          {userDetails?.insurance ? (
            <div>
              Selected Primary Insurance: <b>{userDetails?.insurance}</b>{" "}
            </div>
          ) : (
            <>
              <Divider>Select Anyone</Divider>
              <Form.Item name="paymentStatus">
                <Checkbox onChange={handleCheckboxChange}>
                  {FORM_FIELDS.SELF_PAY}($360) - Proceed Without Insurance
                </Checkbox>
              </Form.Item>
              <Form.Item
                label={FORM_FIELDS.PRIMARY_INSURANCE}
                name="insurance"
                size="large"
                rules={[
                  {
                    required: !selfPay,
                  },
                ]}
              >
                <Select
                  showSearch
                  size="large"
                  placeholder="Please Select"
                  options={insurances}
                  readOnly={selfPay}
                  disabled={selfPay}
                  onChange={handleInsuranceChange}
                />
              </Form.Item>
            </>
          )}

          <Form.Item
            label="Rent device for?"
            name="days"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
            className="mt2"
          >
            <Radio.Group className="mt05">
              <Radio value={1}>1 Day</Radio>
              <Radio value={2}>2 Days</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            {centalisedSubmitBtn(loading, BUTTONS.NEXT, null, 10)}
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
export default Location;
