import BASELINE from "../../Assets/images/WhatWeDo/baseline.png";
import REALTIME from "../../Assets/images/WhatWeDo/realtime.png";
import ARTIFICIAL from "../../Assets/images/WhatWeDo/artificial.png";
import WWD1 from "../../Assets/images/Home/whatWeDo1.png";
import WWD2 from "../../Assets/images/Home/whatWeDo2.png";
import WWD3 from "../../Assets/images/Home/whatWeDo3.png";
import QUALITY from "../../Assets/images/CognitiveJourney/quality.svg";
import INTERVENTION from "../../Assets/images/CognitiveJourney/intervention.svg";
import DETECT from "../../Assets/images/CognitiveJourney/detect.svg";
import ACHIEVE from "../../Assets/images/CognitiveJourney/achieve.png";
import ENHANCE from "../../Assets/images/CognitiveJourney/enhance.png";
import RBS from "../../Assets/images/CognitiveJourney/rbs.png";
import PROACTIVE from "../../Assets/images/Respiratory/proactive.png";
import CONFIDENCE from "../../Assets/images/Respiratory/confidence.png";
import PEACE from "../../Assets/images/Respiratory/peace.png";
import SYMPTOM from "../../Assets/images/Tinnitus/symptom.svg";
import CARE from "../../Assets/images/Tinnitus/care.svg";
import COMMUNICATION from "../../Assets/images/Tinnitus/communication.svg";
import TIN_BASELINE from "../../Assets/images/Tinnitus/baseline.png";
import TIN_REALTIME from "../../Assets/images/Tinnitus/realtime.png";
import TIN_ACTION from "../../Assets/images/Tinnitus/action.png";

export const COMMON = {
  APP_NAME: "SAiWELL",
  LEARN_MORE: "Learn More >",
  READ_MORE: "Read More >",
  SAIGEWARE: {
    VISION: "Saigeware vision",
    DESCRIPTION:
      "We are building the world's largest, multi-marker, deep phenotype dataset and machine learning models to enable personalized healthcare for everyone.",
  },
};

export const HOME = {
  TITLE: "Healthcare Beyond an <br /> Annual Wellness Visit",
  MOBILE_TITLE: "Healthcare Beyond an Annual Wellness Visit",
  SUB_TITLE: "Where you live, Where you work, Where you play",
  DESCRIPTION:
    "SAiWELL, powered by <b>Saigeware</b> keeps the healthcare provider at the center of the patient journey by utilizing real time health data collected from FDA approved sensors",
  WHAT_WE_DO: {
    BLUE_HEADER: "What We",
    ORANGE_HEADER: "Do?",
    CARDS: [
      {
        IMG_SRC: WWD1,
        TITLE: "We See You",
        DESCRIPTION: "We understand you",
        PATH: "/video/importance-of-phenotypic-markers",
        TEXT_CLASS: "black",
        WIDGET_CLASS: "yellow-widget",
        LINK_CLASS: "black-link",
      },
      {
        IMG_SRC: WWD2,
        TITLE: "We Monitor You",
        DESCRIPTION: "Calibrated to your individual needs",
        PATH: "/video/empowering-providers",
        TEXT_CLASS: "white",
        WIDGET_CLASS: "blue-widget",
        LINK_CLASS: "white-link",
      },
      {
        IMG_SRC: WWD3,
        TITLE: "We Guide You",
        DESCRIPTION: "Customized plan for a Sensational Life",
        PATH: "/video/future-of-sensor-technology",
        TEXT_CLASS: "white",
        WIDGET_CLASS: "orange-widget",
        LINK_CLASS: "white-link",
      },
    ],
  },
  FUTURE: {
    BLUE_HEADER: "The Future Of",
    ORANGE_HEADER: "Medicine Is Here",
  },
  HEALTH_JOURNEY: {
    BLUE_HEADER: "Accepting all Insurance Plans to",
    ORANGE_HEADER: "Pay for your SAiWELL Journey",
  },
  ABOUT_US: {
    P1: "Your brain is at the center of how your senses engage with the world. We have tools to understand and help your cognition",
    P2: "Your heart is important to us, and is essential to your body and the ones you love. We keep your heart in the palm of our technology",
    P3: "The way you breath and the way you move gives fuel to your body. Let us help you",
    P4: "The way you talk, the way you hear, and your ability to listen is fundamental to communication. Our tools help connect you with the world",
  },
  TESTIMONIAL: {
    BLUE_HEADER: "Capturing Vitals From Home",
  },
  APP: {
    ORANGE_HEADER: "A Sneak Peek at Our App",
    BLUE_HEADER: "Download Now!",
    DESCRIPTION:
      "Available on iOS. Click the link below to download the app and take control of your health today.",
  },
};

export const WHAT_WE_DO = {
  LABEL: "What We Do",
  TITLE: "What We Do <br /> For Healthcare",
  MOBILE_TITLE: "What We Do For Healthcare",
  SUB_TITLE: "Intimately Connecting the Provider and Patient",
  DESCRIPTION:
    "SAiWELL Solves the Current Roadblocks to Individualized Treatment Plans Through Real Time Monitoring of Your Vitals",
  HIGHLIGHT:
    "Our mission is to give you the ability to live your best life at any age utilizing the latest advances in sensors tailored to interpret what your body is saying",
  TREATMENT: {
    ORANGE_HEADER: "Current Gaps",
    BLUE_HEADER: "To Personalized Treatment",
    PROBLEMS: {
      HEADER: "Problem",
      ONE: {
        TITLE: "Healthcare is not a once a year conversation",
        DESCRIPTION:
          "Primary Care Physicians typically see patients once every 12 months. How can a patient feel confident that a medication is working when they are being monitored by a healthcare provider once every year?",
      },
      TWO: {
        TITLE: "Calling a doctor should be better than a Google search",
        DESCRIPTION:
          "When a healthcare provider has limited visibility into the rapid changes of your body, it is difficult to make accurate assessments to intervene.",
      },
      THREE: {
        TITLE: "Successful Recommendations should not be a coin flip",
        DESCRIPTION:
          "The number of medications and surgical devices available to treat your illnesses are vast. With multiple variables and new treatments offered to you, it is vitally important to understand in real time how those innovations are impacting your body.",
      },
    },
  },
  PATHWAY: {
    ORANGE_HEADER: "Pathway To",
    BLUE_HEADER: "Closing The Gap",
  },
  OUR_MISSION: {
    BLUE_HEADER: "Our",
    ORANGE_HEADER: "Mission",
    HEADER_1: "Better Outcomes: ",
    DESCRIPTION_1:
      "Customized treatments utilizing comprehensive data can lead to better personalized results",
    HEADER_2: "Fewer side effects: ",
    DESCRIPTION_2: "and for a shorter period of time",
    HEADER_3: "Build Trust: ",
    DESCRIPTION_3:
      "Patients are more likely to trust healthcare providers who take the time to personalize their treatment plans, leading to better communication between patients and providers",
    HEADER_4: "Empowerment: ",
    DESCRIPTION_4:
      "Tailored treatments empower patients to take an active role in their healthcare decisions, increasing engagement and motivation to follow treatment plans",
  },
  TRANSFORMS: {
    ORANGE_HEADER: "Our Solution",
    BLUE_HEADER: "We Don't Inform; We Transform",
    CARDS: [
      {
        IMG_SRC: BASELINE,
        TITLE: "Baseline",
        DESCRIPTION:
          "Initially, we understand you, and marry your information with others like you to paint your initial portrait",
        PATH: "/video/importance-of-phenotypic-markers",
        TEXT_CLASS: "black",
        WIDGET_CLASS: "yellow-widget",
        LINK_CLASS: "black-link",
      },
      {
        IMG_SRC: REALTIME,
        TITLE: "Baseline To Realtime",
        DESCRIPTION:
          "Sensors work to interpret what your body is telling you and your healthcare provider on a daily basis",
        PATH: "/video/empowering-providers",
        TEXT_CLASS: "white",
        WIDGET_CLASS: "blue-widget",
        LINK_CLASS: "white-link",
      },
      {
        IMG_SRC: ARTIFICIAL,
        TITLE: "Realtime To Action",
        DESCRIPTION:
          "Programs powered by millions of patient records help to predict what is happening with your body based on the real time data it receives",
        PATH: "/video/future-of-sensor-technology",
        TEXT_CLASS: "white",
        WIDGET_CLASS: "orange-widget",
        LINK_CLASS: "white-link",
      },
    ],
  },
  INFORMATION: {
    DESCRIPTION_1:
      "SAiWELL is committed to giving healthcare providers and patients current, relevant information",
    DESCRIPTION_2:
      "The relevant information is interpreted by science and delivered to you and your healthcare provider; allowing for a meaningful conversation to construct your patient journey",
  },
};

export const COGNITIVE_JOURNEY = {
  TITLE:
    "Monitor Your Cognitive & Physical Wellness for a Better Quality of Life",
  DESCRIPTION: "Understand the link between hearing loss and cognitive health",
  WELLNESS_MATTERS: {
    ORANGE_HEADER: "Why Cognitive",
    BLUE_HEADER: "Wellness Matters?",
    DESCRIPTION:
      "Maintaining cognitive health is crucial for a fulfilling life, allowing us to think clearly, learn new things, and optimize our engagement with the world. This is especially important for individuals experiencing hearing loss. Studies have shown a link between untreated hearing loss and accelerated cognitive decline. The good news is that hearing aids can be used to slow the progression. Monitoring your cognitive wellness through SAiWELL can empower you to take a proactive approach to your overall health.",
  },
  BENEFITS: {
    BLUE_HEADER: "Benefits Of",
    ORANGE_HEADER: "Proactive Hearing Care",
    CARDS: [
      {
        IMG_SRC: DETECT,
        TITLE: "Early Detection",
        DESCRIPTION:
          "The sooner you address hearing loss, the easier it is to manage and potentially slow its progression, and it's impact to the brain. Early detection allows for personalized intervention, maximizing the benefits of treatment.",
        TEXT_CLASS: "white",
        IMG_WIDTH: "120",
        WIDGET_CLASS: "orange-widget",
      },
      {
        IMG_SRC: INTERVENTION,
        TITLE: "Personalized Intervention",
        DESCRIPTION:
          "There's no one-size-fits-all solution for hearing loss. Proactive hearing care provides a tailored approach based on your specific needs and preferences, ensuring the most effective treatment plan.",
        TEXT_CLASS: "white",
        IMG_WIDTH: "80",
        WIDGET_CLASS: "blue-widget",
      },
      {
        IMG_SRC: QUALITY,
        TITLE: "Improved Quality of Life",
        DESCRIPTION:
          "Better hearing means better communication, richer relationships, and a more fulfilling life. Proactive hearing care empowers you to reconnect with the world around you and enjoy all the sounds life has to offer.",
        TEXT_CLASS: "black",
        IMG_WIDTH: "105",
        WIDGET_CLASS: "yellow-widget",
      },
    ],
  },
  RELATIONSHIP: {
    BLUE_HEADER: "Important Relationship Between Hearing,",
    ORANGE_HEADER: "Brain Health, and Social Interaction",
    COGNITIVE_RESEARCH: {
      ORANGE_HEADER: "Cognition",
      BLUE_HEADER: "Research",
      DESCRIPTION:
        "Hearing loss has been increasingly recognized as a significant factor in cognitive health, with numerous studies suggesting a link between the two. Here are some key points regarding this relationship:",
      LI_1: "<b>1. Increased Risk of Dementia: </b> Research indicates that hearing loss is associated with a higher risk of developing dementia. Studies have found that individuals with moderate to severe hearing loss are more likely to experience cognitive decline compared to those with normal hearing.",
      LI_2: "<b>2. Cognitive Load:</b> Hearing loss can increase the cognitive load on the brain. When hearing is impaired, the brain has to work harder to interpret sounds and speech, which can detract from other cognitive processes and lead to mental fatigue.",
      LI_3: "<b>3. Social Isolation:</b> Hearing loss often leads to social isolation as individuals may find it difficult to engage in conversations and participate in social activities. Social isolation is a known risk factor for cognitive decline and dementia.",
      LI_4: "<b>4. Brain Structure Changes: </b> Hearing loss has been linked to changes in brain structure, particularly in areas responsible for processing auditory information. This can affect overall brain health and cognitive function.",
      LI_5: "<b>5. Interventions:</b> Addressing hearing loss through hearing aids or cochlear implants may help mitigate some of the cognitive decline associated with hearing impairment. There is evidence suggesting that treating hearing loss can improve communication, social engagement, and potentially slow cognitive decline.",
      LI_6: "<b>6. Early Detection:</b> Early detection and management of hearing loss are crucial. Regular hearing screenings, especially for older adults, can help identify hearing issues early and allow for timely intervention, which may benefit cognitive health. Understanding the connection between hearing loss and cognitive health highlights the importance of hearing care as part of overall health management, particularly for aging populations.",
    },
  },
  CLINICAL_STUDIES: {
    BLUE_HEADER: "Landmark",
    ORANGE_HEADER: "Clinical Studies",
    CARDS: [
      {
        IMG_SRC: ACHIEVE,
        TITLE: "ACHIEVE Study",
        DESCRIPTION:
          "A landmark study of the effect of hearing intervention on brain health in older adults. Large-scale randomized controlled trial",
        PATH: "https://www.achievestudy.org/",
        TEXT_CLASS: "fs20",
      },
      {
        IMG_SRC: ENHANCE,
        TITLE: "The ENHANCE Study",
        DESCRIPTION:
          "This study is investigating whether remediation of hearing loss in older adults with hearing aids will delay or prevent cognitive decline",
        PATH: "https://healthsciences.unimelb.edu.au/research-groups/audiology-and-speech-pathology-research/hearing-and-cognition-research-program/research/remediation-hearing-aids",
        TEXT_CLASS: "fs20",
      },
      {
        IMG_SRC: RBS,
        TITLE: "RBS of Healthy Aging",
        DESCRIPTION:
          "The results of the study show that hearing loss is associated with regionally specific brain changes that may occur due to sensory deprivation",
        PATH: "https://today.ucsd.edu/story/hearing-loss-is-associated-with-subtle-changes-in-the-brain",
        TEXT_CLASS: "fs20",
      },
    ],
  },
  HEALTH_PARTNER: {
    TITLE: "Your Personalized Hearing Health Partner",
    DESCRIPTION:
      "SAiWELL goes beyond simply improving your hearing. It's a comprehensive hearing health monitoring system that takes a personalized approach. By collecting baseline data and monitoring sensor information from your hearing aids, SAiWELL creates a unique risk profile and provides real-time insights into your overall well-being. This empowers you to take proactive steps towards a healthier future.",
  },
  VIDEOS: {
    BLUE_HEADER: "Learn More About",
    ORANGE_HEADER: "Hearing Loss & Cognitive Health",
  },
};

export const VIDEOS = {
  TITLE: "Sensational Videos <br /> for Patients",
  MOBILE_TITLE: "Sensational Videos for Patients",
  SUB_TITLE: "Explaining the Importance of Personalized Medicine",
  DESCRIPTION:
    '<span class="normal-text bold">Providing Clarity to a Complex Healthcare Journey</span>... Understand The Relationship Between a Provider, The Patient, and Data Science',
};

export const FOR_PATEINTS = {
  TITLE: "Your Journey To A <br /> Sensational Life",
  MOBILE_TITLE: "Your Journey To A Sensational Life",
  SUB_TITLE: "Connecting You and Your Provider to Live Your Best Life",
  DESCRIPTION:
    "<b>Welcome to the center of integrated medicine;</b> utilizing sensor technology and data science to empower patients and providers to personalize healthcare",
  FEATURES: {
    ONE: {
      ORANGE_HEADER: "Sensational",
      BLUE_HEADER: "Care",
      DESCRIPTION:
        'Sensational care is not virtual, or replaced by Artificial Intelligence. Sensational Healthcare is <span class="bold italic blue">Human</span> and driven by humanity, powered by data science',
    },
    TWO: {
      BLUE_HEADER: "Vitals",
      ORANGE_HEADER: "Captured",
      DESCRIPTION:
        "We collect and understand your body's vital signs where it matters most: where you live, where you work, and where you play",
    },
    THREE: {
      ORANGE_HEADER: "Registration",
      BLUE_HEADER: "Process",
      DESCRIPTION:
        "Welcome to SAiWELL! Join thousands of others who are already experiencing the benefits of live data monitoring. Experience now to get started!",
    },
    FOUR: {
      BLUE_HEADER: "Take Charge of",
      ORANGE_HEADER: "Your Health",
      DESCRIPTION:
        "Our cutting-edge monitoring system goes beyond tracking. We help you decode your body's data, revealing insights for a more vibrant you.",
    },
    FIVE: {
      ORANGE_HEADER: "Patient",
      BLUE_HEADER: "Timeline",
      DESCRIPTION:
        "Our interactive timeline provides a clear roadmap for your surgical journey, from pre-operative preparation to post-operative recovery.",
    },
  },
};

export const FOR_PROVIDERS = {
  TITLE: "Focus on Patients Using <br /> Sensational Data",
  MOBILE_TITLE: "Focus on Patients Using Sensational Data",
  SUB_TITLE: "We Manage The Data So You Can Manage Your Patients",
  DESCRIPTION:
    'Access to a Digital Quality Care Team to Advocate for Patients Where They Live Their Life: <span class="normal">Beyond the Clinic</span>',
  CAPTION: {
    ORANGE_HEADER: "Remote Patient Monitoring",
    BLUE_HEADER: "Benefits to The Provider",
    DESCRIPTION:
      "Remote Patient Monitoring (RPM) offers several economic benefits to physicians, contributing to both direct and indirect value.",
  },
  DIRECT_FINANCIAL_BENEFITS: {
    BLUE_HEADER: "Direct",
    ORANGE_HEADER: "Financial Benefits",
    ONE: '<div class="paragraph mt2 fs16"><b>1. Reimbursement Opportunities:</b><ul><li><p>Medicare and Medicaid Reimbursements: Various CPT codes allow physicians to receive reimbursements for providing RPM services. For example, codes like 99453, 99454, 99457, and 99458 cover initial setup, device supply, and ongoing monitoring and management.</p></li><li><p>Private Insurance: Many private insurers also reimburse for RPM, often aligning with Medicare\'s policies.</p></li></ul></div>',
    TWO: '<div class="paragraph mt05 fs16"><b>2. Increased Revenue Streams:</b><ul><li><p>Chronic Care Management: RPM can be integrated into chronic care management programs, enabling additional billing opportunities.</p></li><li><p>Higher Patient Volume: The ability to monitor patients remotely allows physicians to manage more patients effectively without requiring physical visits.</p></li></ul></div>',
    THREE:
      '<div class="paragraph mt05 fs16"><b>3. Cost Savings:</b><ul><li><p>Reduced No-Shows: Virtual monitoring reduces the incidence of missed appointments, thereby increasing revenue consistency.</p></li><li><p>Operational Efficiency: RPM can decrease the need for frequent in-person visits, reducing the overhead costs associated with running a physical office.</p></li></ul></div>',
  },
  INDIRECT_FINANCIAL_BENEFITS: {
    BLUE_HEADER: "Indirect",
    ORANGE_HEADER: "Financial Benefits",
    ONE: '<div class="paragraph mt2 fs16"><b>1. Improved Patient Outcomes:</b><ul><li><p>Preventative Care: Early detection and management of health issues through RPM can lead to better patient outcomes, reducing hospital readmissions and associated penalties.</p></li><li><p>Patient Satisfaction and Retention: Enhanced patient engagement and satisfaction can lead to higher retention rates, indirectly boosting long-term revenue.</p></li></ul></div>',
    TWO: '<div class="paragraph mt05 fs16"><b>2. Enhanced Practice Reputation:</b><ul><li><p>Competitive Advantage: Offering advanced services like RPM can differentiate a practice from competitors, attracting more patients.</p></li><li><p>Quality Metrics and Ratings: Improved patient outcomes and satisfaction scores can enhance a practice’s ratings and reputation, potentially leading to more referrals.</p></li></ul></div>',
    THREE:
      '<div class="paragraph mt05 fs16"><b>3. Data-Driven Decision Making:</b><ul><li><p>Clinical Insights: Continuous data collection allows for better-informed clinical decisions, optimizing treatment plans and improving efficiency.</p></li><li><p>Resource Allocation: Identifying patients who need more intensive care can help allocate resources more effectively, ensuring better care delivery and cost management.</p></li></ul></div>',
  },
  FEATURES: {
    ONE: {
      BLUE_HEADER: "Patient",
      ORANGE_HEADER: "Monitoring",
      DESCRIPTION:
        "Our intuitive provider dashboard delivers real-time patient data in a centralized location, informed care and improved clinical outcomes.",
    },
    TWO: {
      ORANGE_HEADER: "Personalized",
      BLUE_HEADER: "Experience",
      DESCRIPTION:
        "Our provider profile page empowers you to manage your preferences, access resources, and collaborate seamlessly with colleagues, fostering a personalized and efficient healthcare environment.",
    },
    THREE: {
      BLUE_HEADER: "Better Outcomes",
      ORANGE_HEADER: "Increased Revenue",
      DESCRIPTION:
        "Our provider and administrator dashboard track each patient by month, by payer, and status of the RPM and RTM payment.",
    },
    FOUR: {
      ORANGE_HEADER: "Proactive Care",
      BLUE_HEADER: "Starts Here",
      DESCRIPTION:
        "Our advanced alert system on the provider dashboard notifies you of critical changes in patient data, allowing for immediate action and improved patient outcomes.",
    },
  },
};

export const FAQ = {
  TITLE: "Frequently <br /> Asked Questions",
  MOBILE_TITLE: "Frequently Asked Questions",
  SUB_TITLE: "Better Understand Your Healthcare Journey Away From the Clinic",
  DESCRIPTION:
    "Answering Common Questions Patients, Caregivers, and Loved Ones Might Have About Sophisticated <b>Remote Patient Monitoring (RPM)</b>",
};

export const CONTACT_US = {
  TITLE: "Contact Us To SAiWELL",
  DESCRIPTION:
    "<b>Fill out the form below to send us a message.</b> We'll get back to you as soon as possible",
};

export const PATIENT = {
  BOOKINGS: {
    REVIEW: {
      TITLE: "Instructions:",
      NOTE: "To proceed further you need to sign an agreement. An agreement document has been sent to your mail. Please read and digitally sign the document. If already signed please ignore. If you haven't received the mail ",
    },
  },
};

export const RESPIRATORY_CARE = {
  TITLE: "Proactive Respiratory Management Fueled By Real Time Data Analysis",
  DESCRIPTION:
    "Leveraging the Power of Sensors and Data Science to Maximize Your Every Breath",
  FEATURES: {
    ONE: {
      ORANGE_HEADER: "Personalized",
      BLUE_HEADER: "Respiratory Care",
      DESCRIPTION:
        "Understanding the way you breath as you experience life is essential when living with chronic respiratory conditions like asthma, COPD, and allergies. Our Remote Patient Monitoring program empowers you to take control of your health by providing real-time monitoring and personalized care from the comfort of your home. Through easy-to-use equipment and secure data transmission, healthcare professionals can remotely monitor your vital signs, symptoms, and medication adherence. This allows for early intervention, proactive adjustments to your care plan, and ultimately, improved respiratory health and well-being",
    },
    TWO: {
      BLUE_HEADER: "Targeted Asthma",
      ORANGE_HEADER: "Management",
      DESCRIPTION:
        "Struggling with asthma symptoms like wheezing, coughing, and shortness of breath? Our  Remote Patient Monitoring program provides tools and support to help you manage your asthma and prevent flare-ups. Use our provided equipment to easily track your peak flow meter readings and other vital signs. This valuable data allows your healthcare provider to monitor your condition remotely and adjust your medication plan as needed, preventing flare-ups before they start.",
    },
    THREE: {
      ORANGE_HEADER: "Breathe Easier with COPD",
      BLUE_HEADER: "Management Through RPM",
      DESCRIPTION:
        "Living with COPD can be challenging. Our RPM program helps you manage your symptoms and improve your quality of life. With our easy-to-use equipment, you can monitor your oxygen levels, heart rate, and other vital signs from home. This data allows your healthcare provider to identify potential complications early and adjust your care plan accordingly.",
    },
    FOUR: {
      BLUE_HEADER: "Take Control of Allergies",
      ORANGE_HEADER: "With Real Time Management",
      DESCRIPTION:
        "Allergies can disrupt your daily routine and cause discomfort. Our Remote Monitoring can help you identify your triggers and manage your symptoms effectively. Through our platform, you can track your symptoms and environmental factors that may be causing your allergies. This information allows your healthcare provider to develop a personalized treatment plan to minimize your allergy impact",
    },
  },
  RPM_BENEFITS: {
    BLUE_HEADER: "Benefits of Precision Monitoring",
    ORANGE_HEADER: "For Respiratory Health",
    CARDS: [
      {
        IMG_SRC: PROACTIVE,
        TITLE: "Proactive Management",
        DESCRIPTION:
          "Our RPM program provides real-time data that allows your healthcare provider to monitor your condition remotely and intervene proactively to prevent complications and manage exacerbations This proactive approach translates to improved overall respiratory health and fewer flare-ups.",
      },
      {
        IMG_SRC: CONFIDENCE,
        TITLE: "Self-Management & Confidence",
        DESCRIPTION:
          "With RPM, you become an active participant in managing your respiratory condition. By tracking symptoms, medication adherence, and vital signs, you gain valuable insights into your health.  This empowers you to make informed decisions and fosters a sense of control and confidence in your health journey.",
      },
      {
        IMG_SRC: PEACE,
        TITLE: "Reduced Costs & Peace of Mind",
        DESCRIPTION:
          "Early intervention and proactive management with RPM can help prevent unnecessary hospital visits and readmissions. This translates to potential cost savings for both patients and healthcare systems. Additionally, knowing your health is being monitored remotely by your healthcare provider offers peace of mind",
      },
    ],
  },
};

const HERE_IS = "Here's";

export const TINNITUS = {
  TITLE: "Discover Tinnitus Relief: A New Approach to Managing Symptoms",
  DESCRIPTION:
    "Introducing our innovative process to understand your symptoms, and be able to provide solutions using real time data",
  FEATURES: {
    ONE: {
      BLUE_HEADER: "What Is",
      ORANGE_HEADER: "Tinnitus?",
      DESCRIPTION:
        "Tinnitus is the perception of sound when there is no external noise present. This condition is often described as ringing, buzzing, or hissing in the ears. Tinnitus can vary in intensity and duration, affecting individuals differently. It's a common issue that can impact your quality of life by disrupting concentration, sleep, and emotional well-being.",
    },
    TWO: {
      ORANGE_HEADER: "Causes",
      BLUE_HEADER: "Of Tinnitus",
      DESCRIPTION:
        'Tinnitus can arise from a variety of underlying issues. Here are some common causes: <ul class="list-paragraph"><li><b>Hearing Loss:</b> Age-related hearing loss or prolonged exposure to loud noises can damage the inner ear, leading to tinnitus.</li> <li><b>Ear Infections:</b> Infections can cause inflammation and pressure changes, contributing to tinnitus.</li> <li><b>Ear Blockages:</b> Wax buildup or foreign objects in the ear can lead to ringing or buzzing sounds.</li> <li><b>Medications:</b> Certain medications, particularly those with ototoxic effects, can induce tinnitus.</li> <li><b>Medical Conditions:</b> Conditions such as high blood pressure, diabetes, and thyroid issues can also be linked to tinnitus.</li></ul>',
    },
    WHAT_WE_DO: {
      BLUE_HEADER: "What We",
      ORANGE_HEADER: "Do?",
      CARDS: [
        {
          IMG_SRC: TIN_BASELINE,
          TITLE: "Baseline",
          DESCRIPTION:
            "Initially, we understand you, and marry your information with others like you to paint your initial portrait",
          BORDER: "#F7BA11",
        },
        {
          IMG_SRC: TIN_REALTIME,
          TITLE: "Realtime",
          DESCRIPTION:
            "Sensors work to interpret what your body is telling you and your healthcare provider on a daily basis",
          BORDER: "#34B19E",
        },
        {
          IMG_SRC: TIN_ACTION,
          TITLE: "Action",
          DESCRIPTION:
            "AI/ML powered by millions of patient records help to predict what is happening with your body based on the real time data it receives",
          BORDER: "#F26511",
        },
      ],
    },
    THREE: {
      BLUE_HEADER: "How We Help You",
      ORANGE_HEADER: "Manage Tinnitus",
      DESCRIPTION: `Our RPM solution is designed to provide comprehensive support for managing tinnitus. ${HERE_IS} how we assist you: <ul class="list-paragraph"><li><b>Continuous Monitoring:</b> Track your symptoms and health metrics in real time to gain a clear understanding of your condition.</li> <li><b>Personalized Insights:</b> Receive customized recommendations and strategies based on your specific data, helping you manage symptoms more effectively.</li> <li><b>Remote Support:</b> Access support from healthcare professionals without the need for frequent in-person visits, ensuring consistent care and guidance.</li></ul>`,
    },
  },
  PATHWAY: {
    ORANGE_HEADER: "SAiWELL Solution Pathway",
    BLUE_HEADER_1: "Get Well",
    BLUE_HEADER_2: "Stay Well",
  },
  THERAPY: {
    ORANGE_HEADER: "Therapeutic Intervention",
    BLUE_HEADER_1: "Get Well",
    BLUE_HEADER_2: "Stay Well",
  },
  BENEFITS: {
    BLUE_HEADER: "The Benefits of Our",
    ORANGE_HEADER: "Customized Real-Time Solution",
    CARDS: [
      {
        IMG_SRC: SYMPTOM,
        TITLE: "Symptom Control",
        DESCRIPTION:
          " Leverage data-driven insights and personalized care plans to better manage and control your tinnitus symptoms.",
        TEXT_CLASS: "black",
        WIDGET_CLASS: "yellow-widget",
      },
      {
        IMG_SRC: CARE,
        TITLE: "Proactive Care",
        DESCRIPTION:
          "Monitor your condition from home with ease, allowing for timely intervention and adjustments to your care plan, while reducing stress and saving time.",
        TEXT_CLASS: "white",
        WIDGET_CLASS: "blue-widget",
      },
      {
        IMG_SRC: COMMUNICATION,
        TITLE: "Improved Communication",
        DESCRIPTION:
          "Share detailed, real-time data with your healthcare provider to facilitate more informed and effective treatment decisions.",
        TEXT_CLASS: "white",
        WIDGET_CLASS: "orange-widget",
      },
    ],
  },
};
