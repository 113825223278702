import React, { useEffect, useState } from "react";
import { Button, Col, Flex, Form, Input, Row, Select, Table } from "antd";
import { getApis, postApis } from "../../Common/Apis";
import {
  defaultPageSize,
  providerResolveAlertUrl,
  providerVitalAlertsUrl,
  providerVitalNamesUrl,
} from "../../Common/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { NOTIFICATIONS } from "../../Common/Constants";
import { setAlertFilters } from "../../../Reducers/filterSlice";
import useNotify from "../../Common/Notify";
import { WarningFilled } from "@ant-design/icons";
import ResolveAlert from "./ResolveAlert";
import { displayDateTime, getRemoteConfigData } from "../../Common/Utils";
import { useApiLoader } from "../../Common/ApiLoaderContext";

const { Search } = Input;

const VitalAlerts = () => {
  const [alertList, setAlertList] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [paginationDetails, setPaginationDetails] = useState({
    current: 1,
    pageSize: defaultPageSize,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [fromPage, setFromPage] = useState(1);
  const [forward, setForward] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const { showLoader, setShowLoader } = useApiLoader();
  const [vitalNames, setVitalNames] = useState();
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [staticDropdownData, setStaticDropdownData] = useState();
  const defaultDoctor =
    useSelector((state) => state?.filter?.defaultDoctor) ||
    localStorage.getItem("defaultDoctor");
  const filters = useSelector((state) => state?.filter?.alerts);
  const dispatch = useDispatch();
  const { notify } = useNotify();
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      sortDirections: ["descend"],
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "MRN",
      dataIndex: "mrn",
    },
    {
      title: "Vital",
      dataIndex: "vitalValue",
      render: (_, record) => (
        <div
          className={record?.priority === "High" ? "high-alert" : "low-alert"}
        >
          <WarningFilled className="mr025" /> {record?.vitalValue} {"  "}
          {record?.vitalName}
        </div>
      ),
    },
    {
      title: "Program",
      dataIndex: "service",
    },
    {
      title: "Vital Collected At",
      dataIndex: "vitalCollectedTime",
      render: (_, record) => displayDateTime(record?.vitalCollectedTime),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div className="flex-center">
          {record?.alertStatus === "Pending" ? (
            <Button
              size="small"
              type="primary"
              className="mr05"
              onClick={() => showModal(record)}
            >
              Resolve
            </Button>
          ) : (
            <Button
              size="small"
              className="mr05"
              onClick={() => showModal(record)}
            >
              Resolved
            </Button>
          )}
        </div>
      ),
    },
  ];

  const getVitalNames = async () => {
    await getApis(
      providerVitalNamesUrl,
      defaultDoctor ? { doctor: defaultDoctor } : {}
    )
      .then((response) => {
        setVitalNames(response?.data?.vitalNames);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationDetails, defaultDoctor]);

  useEffect(() => {
    getRemoteConfigData("DROPDOWNS").then((result) =>
      setStaticDropdownData(result)
    );
    getVitalNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDoctor]);

  const showModal = (record) => {
    setSelectedAlert(record);
    setModalOpen(true);
    form.resetFields();
  };

  const cancelModal = () => {
    setSelectedAlert(null);
    setModalOpen(false);
  };

  const handleSearch = (query) => {
    setAlertList({});
    setSearchQuery(query);
    setFromPage(1);
    setForward(true);
    setPaginationDetails({
      ...paginationDetails,
      forward: true,
      current: 1,
      searchBy: query,
      paginationSize: paginationDetails.pageSize,
    });
  };

  const handleStatusChange = (value) => {
    setAlertList({});
    setFromPage(1);
    setForward(true);
    dispatch(setAlertFilters({ status: value }));
    setPaginationDetails({
      ...paginationDetails,
      current: 1,
      forward: true,
      status: value,
      paginationSize: paginationDetails.pageSize,
    });
  };

  const handleVitalNameChange = (value) => {
    setAlertList({});
    setFromPage(1);
    setForward(true);
    dispatch(setAlertFilters({ vitalName: value }));
    setPaginationDetails({
      ...paginationDetails,
      current: 1,
      forward: true,
      vitalName: value,
      paginationSize: paginationDetails.pageSize,
    });
  };

  const getAlerts = async () => {
    setShowLoader(true);
    const defaultParams = {
      forward: forward,
      doctor: defaultDoctor,
      status: filters?.status,
      searchBy: searchQuery,
      vitalName: filters?.vitalName,
      paginationSize: paginationDetails.pageSize,
      firstDate:
        alertList?.firstDate && alertList?.firstDate !== "None"
          ? alertList?.firstDate
          : null,
      lastDate:
        alertList?.lastDate && alertList?.lastDate !== "None"
          ? alertList?.lastDate
          : null,
    };
    if (searchQuery === "") {
      delete defaultParams["searchBy"];
    }
    await getApis(providerVitalAlertsUrl, defaultParams)
      .then((response) => {
        setShowLoader(false);
        setAlertList(response?.data);
        setAlerts(response?.data?.alerts);
      })
      .catch(() => setShowLoader(false));
  };

  const handlePageChange = (pagination) => {
    setFromPage(pagination.current);
    setForward(pagination.current > fromPage);
    setPaginationDetails({
      ...paginationDetails,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handleTextAreaChange = (event) => {
    setOtherReason(event.target.value);
  };

  const handleDropdownChange = (value) => {
    setSelectedReason(value);
    setOtherReason("");
  };

  const onFinish = async () => {
    const formData = {
      reason: selectedReason,
      description: otherReason,
      vitalName: selectedAlert?.vitalName,
      vitalValue: selectedAlert?.vitalValue,
      mrn: selectedAlert?.mrn,
      service: selectedAlert?.service,
      provider: defaultDoctor,
      vitalCollectedTime: new Date(selectedAlert?.vitalCollectedTime).getTime(),
    };
    setLoading(true);
    await postApis(providerResolveAlertUrl, formData)
      .then((response) => {
        setLoading(false);
        setModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    !showLoader && (
      <div data-testid="vital-alerts">
        <ResolveAlert
          form={form}
          selectedAlert={selectedAlert}
          handleTextAreaChange={handleTextAreaChange}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          handleDropdownChange={handleDropdownChange}
          cancelModal={cancelModal}
          modalOpen={modalOpen}
          loading={loading}
          selectedReason={selectedReason}
        />

        <Row justify={"space-between"} gutter={[16, 8]}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="flex-start-center"
          >
            {defaultDoctor && (
              <Flex wrap="wrap" justify={"start"}>
                <div className="bold mr05">Provider: </div>
                <div>{defaultDoctor}</div>
              </Flex>
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <Select
              size="large"
              value={filters?.status}
              placeholder="Select Status"
              options={staticDropdownData?.common?.alertStatus}
              className="full-width"
              onChange={handleStatusChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Select
              allowClear
              size="large"
              value={filters?.vitalName}
              placeholder="Select Vitals"
              options={vitalNames?.map((item) => {
                return { label: item, value: item };
              })}
              className="full-width"
              onChange={handleVitalNameChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Search
              allowClear
              value={searchQuery}
              placeholder="Search by First Name or MRN"
              onSearch={handleSearch}
              onChange={handleSearchInputChange}
              size="large"
              className="full-width search"
            />
          </Col>
        </Row>
        <Row className="mt1">
          <Col span={24}>
            <Table
              rowKey={(record) =>
                `${record.vitalName}-${record.mrn}-${new Date(
                  record.vitalCollectedTime
                ).getTime()}`
              }
              columns={columns}
              dataSource={alerts}
              scroll={{ x: "100%" }}
              onChange={handlePageChange}
              pagination={{
                current: paginationDetails.current,
                pageSize: paginationDetails.pageSize,
                total: alertList?.totalAlerts,
                showSizeChanger: false,
                showQuickJumper: false,
                hideOnSinglePage: true,
                showTotal: (total, range) => (
                  <div className="mt025">
                    Total <b>{total}</b> Items
                  </div>
                ),
              }}
            />
          </Col>
        </Row>
      </div>
    )
  );
};
export default VitalAlerts;
