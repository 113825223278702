import { Card, Col, Form, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import { getApis, postApis } from "../../Common/Apis";
import {
  providerViewPatientEpisodeUrl,
  providerViewPatientProfileUrl,
  providerViewPatientGraphsUrl,
  providerViewPatientVitalsUrl,
  providerViewPatientAlertsUrl,
  providerViewPatientUnlinkDeviceUrl,
  providerViewPatientTimelinesUrl,
  providerViewPatientFeNoUrl,
  providerViewPatientClaimsUrl,
  providerViewPatientQuestionnairesUrl,
  providerViewPatientEmrDataUrl,
  providerViewPatientReportsUrl,
  providerViewPatientBestPefGraphUrl,
  providerResolveAllAlertsUrl,
  providerViewPatientVialsUrl,
} from "../../Common/Endpoints";
import { useParams } from "react-router-dom";
import { setSelectedEpisode } from "../../../Reducers/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import Graphs from "./Graphs";
import Overview from "./Overview";
import Vitals from "./Vitals";
import WithEpisode from "./WithEpisode";
import Alerts from "./Alerts";
import Devices from "./Devices";
import Claims from "./Claims";
import Timelines from "./Timelines";
import WithoutEpisode from "./WithoutEpisode";
import FeNo from "./FeNo";
import {
  setAlertDetails,
  setBestPefDetails,
  setClaimDetails,
  setDeviceDetails,
  setEmrDetails,
  setEpisodeDetails,
  setFeNoDetails,
  setGraphDetails,
  setProfileDetails,
  setQuestionnaireDetails,
  setReportDetails,
  setTimelineDetails,
  setVialDetails,
  setVitalDetails,
} from "../../../Reducers/patientSlice";
import Reports from "./Reports";
import WithForm from "./WithForm";
import Questionnaires from "./Questionnaires";
import EmrData from "./EmrData";
import { NOTIFICATIONS, SERVICES } from "../../Common/Constants";
import ResolveAlert from "../Alerts/ResolveAlert";
import useNotify from "../../Common/Notify";
import Dashboard from "./Dashboard";
import Vials from "./Vials";

const PatientDetails = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [showLoader, setShowLoader] = useState(false);
  const [activeTab, setActiveTab] = useState("Overview");
  const profile = useSelector((state) => state.profile);
  const patient = useSelector((state) => state.patient);
  const selectedEpisode = profile?.selectedEpisode;
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { phone, service } = useParams();
  const dispatch = useDispatch();
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [loading, setLoading] = useState(false);
  const { notify } = useNotify();
  const defaultDoctor =
    useSelector((state) => state?.filter?.defaultDoctor) ||
    `${userDetails?.firstName} ${userDetails?.lastName}`;

  const getEpisodes = async (token) => {
    await getApis(providerViewPatientEpisodeUrl, {
      token: token,
      service: service,
    })
      .then((response) => {
        dispatch(setEpisodeDetails(response?.data));
        dispatch(setSelectedEpisode(response?.data?.currentEpisode));
      })
      .catch(() => {});
  };

  const getProfileData = async () => {
    if (!patient?.profileDetails) {
      setShowLoader(true);
      await getApis(providerViewPatientProfileUrl, {
        phoneNumber: phone.toString(),
      })
        .then((response) => {
          setShowLoader(false);
          dispatch(setProfileDetails(response?.data));
          getEpisodes(response?.data?.token);
        })
        .catch(() => setShowLoader(false));
    }
  };

  const getAlerts = async (episodeNumber) => {
    const episode = episodeNumber || selectedEpisode;
    if (
      patient?.episodeDetails &&
      Object.keys(patient?.episodeDetails).length !== 0
    ) {
      setShowLoader(true);
      await getApis(providerViewPatientAlertsUrl, {
        token: patient?.profileDetails?.token,
        service: service,
        episodeNumber: episode || patient?.episodeDetails?.currentEpisode,
      })
        .then((response) => {
          setShowLoader(false);
          dispatch(setAlertDetails(response?.data));
        })
        .catch(() => setShowLoader(false));
    }
  };

  const getDevices = async (reload) => {
    if (!patient?.deviceDetails || reload) {
      setShowLoader(true);
      await getApis(providerViewPatientUnlinkDeviceUrl, {
        token: patient?.profileDetails?.token,
        service: service,
      })
        .then((response) => {
          setShowLoader(false);
          dispatch(setDeviceDetails(response?.data));
        })
        .catch(() => setShowLoader(false));
    }
  };

  const getTimelines = async () => {
    if (!patient?.timelineDetails) {
      setShowLoader(true);
      await getApis(providerViewPatientTimelinesUrl, {
        token: patient?.profileDetails?.token,
        service: service,
      })
        .then((response) => {
          setShowLoader(false);
          dispatch(setTimelineDetails(response?.data));
        })
        .catch(() => setShowLoader(false));
    }
  };

  const getQuestionnaires = async (episodeNumber) => {
    const episode = episodeNumber || selectedEpisode;

    setShowLoader(true);
    await getApis(providerViewPatientQuestionnairesUrl, {
      token: patient?.profileDetails?.token,
      service: service,
      episodeNumber: episode || patient?.episodeDetails?.currentEpisode,
    })
      .then((response) => {
        setShowLoader(false);
        dispatch(setQuestionnaireDetails(response?.data));
      })
      .catch(() => setShowLoader(false));
  };

  const getFeNoData = async (reload) => {
    if (!patient?.feNoDetails || reload) {
      setShowLoader(true);
      await getApis(providerViewPatientFeNoUrl, {
        token: patient?.profileDetails?.token,
        service: service,
      })
        .then((response) => {
          setShowLoader(false);
          dispatch(setFeNoDetails(response?.data));
        })
        .catch(() => setShowLoader(false));
    }
  };

  const getReports = async (episodeNumber) => {
    const episode = episodeNumber || selectedEpisode;
    if (
      patient?.episodeDetails &&
      Object.keys(patient?.episodeDetails).length !== 0
    ) {
      setShowLoader(true);

      await getApis(providerViewPatientReportsUrl, {
        token: patient?.profileDetails?.token,
        service: service,
        episodeNumber: episode || patient?.episodeDetails?.currentEpisode,
      })
        .then((response) => {
          setShowLoader(false);
          dispatch(setReportDetails(response?.data));
        })
        .catch(() => setShowLoader(false));
    }
  };

  const getClaimsData = async () => {
    setShowLoader(true);
    await getApis(providerViewPatientClaimsUrl, {
      token: patient?.profileDetails?.token,
      service: service,
    })
      .then((response) => {
        setShowLoader(false);
        dispatch(setClaimDetails(response?.data));
      })
      .catch(() => setShowLoader(false));
  };

  const getVialsData = async () => {
    setShowLoader(true);
    await getApis(providerViewPatientVialsUrl, {
      token: patient?.profileDetails?.token,
      service: service,
    })
      .then((response) => {
        setShowLoader(false);
        dispatch(setVialDetails(response?.data));
      })
      .catch(() => setShowLoader(false));
  };

  const getGraphs = async (episodeNumber) => {
    const episode = episodeNumber || selectedEpisode;
    if (
      patient?.episodeDetails &&
      Object.keys(patient?.episodeDetails).length !== 0
    ) {
      setShowLoader(true);
      await getApis(providerViewPatientGraphsUrl, {
        token: patient?.profileDetails?.token,
        service: service,
        episodeNumber: episode || patient?.episodeDetails?.currentEpisode,
      })
        .then((response) => {
          setShowLoader(false);
          dispatch(setGraphDetails(response?.data));
        })
        .catch(() => setShowLoader(false));
    }
  };

  const getBestPefData = async () => {
    if (
      patient?.episodeDetails &&
      Object.keys(patient?.episodeDetails).length !== 0 &&
      [SERVICES.BIOLOGICS, SERVICES.SLIT].includes(service)
    ) {
      await getApis(providerViewPatientBestPefGraphUrl, {
        token: patient?.profileDetails?.token,
        service: service,
      })
        .then((response) => {
          dispatch(setBestPefDetails(response?.data));
        })
        .catch(() => {});
    }
  };

  const getVitals = async (episodeNumber) => {
    const episode = episodeNumber || selectedEpisode;
    if (
      patient?.episodeDetails &&
      Object.keys(patient?.episodeDetails).length !== 0
    ) {
      setShowLoader(true);
      await getApis(providerViewPatientVitalsUrl, {
        token: patient?.profileDetails?.token,
        service: service,
        episodeNumber: episode || patient?.episodeDetails?.currentEpisode,
      })
        .then((response) => {
          setShowLoader(false);
          dispatch(setVitalDetails(response?.data));
        })
        .catch(() => setShowLoader(false));
    }
  };

  const getEmrData = async (reload) => {
    if (!patient?.emrDetails || reload) {
      setShowLoader(true);
      await getApis(providerViewPatientEmrDataUrl, {
        token: patient?.profileDetails?.token,
        service: service,
      })
        .then((response) => {
          setShowLoader(false);
          dispatch(setEmrDetails(response?.data));
        })
        .catch(() => setShowLoader(false));
    }
  };

  useEffect(() => {
    if (phone) {
      getProfileData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEpisodeChange = (value) => {
    if (selectedEpisode !== value) {
      callTabBasedApis(activeTab, value, true);
      dispatch(setSelectedEpisode(value));
    }
  };

  const callTabBasedApis = (tab, episodeNumber) => {
    switch (tab) {
      case "dashboard":
        getGraphs(episodeNumber);
        getBestPefData();
        getVitals(episodeNumber);
        break;
      case "vitals":
        getVitals(episodeNumber);
        break;
      case "graphs":
        getGraphs(episodeNumber);
        getBestPefData();
        break;
      case "alerts":
        getAlerts(episodeNumber);
        break;
      case "devices":
        getDevices();
        break;
      case "timelines":
        getTimelines();
        break;
      case "feNo":
        getFeNoData();
        break;
      case "claims":
        getClaimsData();
        break;
      case "vials":
        getVialsData();
        break;
      case "questionnaires":
        getQuestionnaires(episodeNumber);
        break;
      case "emrData":
        getEmrData();
        break;
      case "reports":
        getReports(episodeNumber);
        break;
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    callTabBasedApis(key);
  };

  const showModal = () => {
    setModalOpen(true);
    form.resetFields();
  };

  const cancelModal = () => {
    setModalOpen(false);
  };

  const tabItems = [
    {
      key: "overview",
      label: "Overview",
      children: (
        <WithoutEpisode
          showLoader={showLoader}
          details={patient?.profileDetails}
        >
          <Overview
            service={service}
            episodeDetails={patient?.episodeDetails}
            profileDetails={patient?.profileDetails}
          />
        </WithoutEpisode>
      ),
    },
    {
      key: "dashboard",
      label: "Dashboard",
      children: (
        <WithEpisode
          selectedEpisode={selectedEpisode}
          episodeDetails={patient?.episodeDetails}
          onEpisodeChange={onEpisodeChange}
          showLoader={showLoader}
          details={patient?.bestPefDetails}
        >
          <Dashboard
            vitalDetails={patient?.vitalDetails}
            graphDetails={patient?.graphDetails}
            bestPefDetails={patient?.bestPefDetails}
          />
        </WithEpisode>
      ),
    },
    {
      key: "vitals",
      label: "Vitals",
      children: (
        <WithEpisode
          selectedEpisode={selectedEpisode}
          episodeDetails={patient?.episodeDetails}
          onEpisodeChange={onEpisodeChange}
          showLoader={showLoader}
          details={patient?.vitalDetails}
        >
          <Vitals vitalDetails={patient?.vitalDetails} />
        </WithEpisode>
      ),
    },
    {
      key: "graphs",
      label: "Graphs",
      children: (
        <WithEpisode
          selectedEpisode={selectedEpisode}
          episodeDetails={patient?.episodeDetails}
          onEpisodeChange={onEpisodeChange}
          showLoader={showLoader}
          details={patient?.graphDetails}
        >
          <Graphs graphDetails={patient?.graphDetails} />
        </WithEpisode>
      ),
    },
    {
      key: "alerts",
      label: "Alerts",
      children: (
        <WithEpisode
          selectedEpisode={selectedEpisode}
          episodeDetails={patient?.episodeDetails}
          onEpisodeChange={onEpisodeChange}
          showLoader={showLoader}
          details={patient?.alertDetails}
          activeTab={activeTab}
          showModal={showModal}
          cancelModal={cancelModal}
        >
          <Alerts
            alertDetails={patient?.alertDetails}
            mrn={patient?.profileDetails?.mrn}
            service={service}
            defaultDoctor={defaultDoctor}
          />
        </WithEpisode>
      ),
    },
    {
      key: "vials",
      label: "Vials",
      children: (
        <WithoutEpisode showLoader={showLoader} details={patient?.vialDetails}>
          <Vials
            vialDetails={patient?.vialDetails}
            notify={notify}
            service={service}
            token={patient?.profileDetails?.token}
            defaultDoctor={defaultDoctor}
          />
        </WithoutEpisode>
      ),
    },
    {
      key: "claims",
      label: "Claims",
      children: (
        <WithoutEpisode showLoader={showLoader} details={patient?.claimDetails}>
          <Claims claimDetails={patient?.claimDetails} />
        </WithoutEpisode>
      ),
    },
    {
      key: "feNo",
      label: "FeNO",
      children: (
        <WithForm showLoader={showLoader}>
          <FeNo
            feNoDetails={patient?.feNoDetails}
            token={patient?.profileDetails?.token}
            getFeNoData={getFeNoData}
          />
        </WithForm>
      ),
    },
    {
      key: "timelines",
      label: "Timelines",
      children: (
        <WithoutEpisode
          showLoader={showLoader}
          details={patient?.timelineDetails}
        >
          <Timelines
            timelineDetails={patient?.timelineDetails}
            clinic={userDetails?.clinic}
          />
        </WithoutEpisode>
      ),
    },
    {
      key: "emrData",
      label: "EMR Data",
      children: (
        <WithForm showLoader={showLoader}>
          <EmrData
            emrDetails={patient?.emrDetails}
            token={patient?.profileDetails?.token}
            getEmrData={getEmrData}
          />
        </WithForm>
      ),
    },
    {
      key: "questionnaires",
      label: "Questionnaires",
      children: (
        <WithEpisode
          selectedEpisode={selectedEpisode}
          episodeDetails={patient?.episodeDetails}
          onEpisodeChange={onEpisodeChange}
          showLoader={showLoader}
          details={patient?.questionnaireDetails}
        >
          <Questionnaires
            questionnaireDetails={patient?.questionnaireDetails}
            service={service}
            clinic={userDetails?.clinic}
            profileDetails={patient?.profileDetails}
            episodeDetails={patient?.episodeDetails}
          />
        </WithEpisode>
      ),
    },

    {
      key: "reports",
      label: "Reports",
      children: (
        <WithEpisode
          selectedEpisode={selectedEpisode}
          episodeDetails={patient?.episodeDetails}
          onEpisodeChange={onEpisodeChange}
          showLoader={showLoader}
          details={patient?.reportDetails}
        >
          <Reports
            service={service}
            selectedEpisode={selectedEpisode}
            reportDetails={patient?.reportDetails}
            episodeDetails={patient?.episodeDetails}
            token={patient?.profileDetails?.token}
          />
        </WithEpisode>
      ),
    },
    {
      key: "devices",
      label: "Devices",
      children: (
        <WithoutEpisode
          showLoader={showLoader}
          details={patient?.deviceDetails}
        >
          <Devices
            deviceDetails={patient?.deviceDetails}
            token={patient?.profileDetails?.token}
            service={service}
          />
        </WithoutEpisode>
      ),
    },
  ];

  const onFinish = async () => {
    const formData = {
      reason: selectedReason,
      description: otherReason,
      token: patient?.profileDetails?.token,
      episodeNumber: selectedEpisode,
      service: service,
    };
    setLoading(true);
    await postApis(providerResolveAllAlertsUrl, formData)
      .then((response) => {
        setLoading(false);
        setModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const handleTextAreaChange = (event) => {
    setOtherReason(event.target.value);
  };

  const handleDropdownChange = (value) => {
    setSelectedReason(value);
    setOtherReason("");
  };

  const getTabMenus = () => {
    if (service === SERVICES.GENERAL) {
      return tabItems.filter(
        (item) =>
          ![
            "questionnaires",
            "feNo",
            "timelines",
            "emrData",
            "dashboard",
            "vials",
          ].includes(item.key)
      );
    } else if (service === SERVICES.SLIT) {
      return tabItems.filter(
        (item) =>
          !["timelines", "emrData", "vitals", "graphs"].includes(item.key)
      );
    } else if (service === SERVICES.WELLNESS) {
      return tabItems.filter((item) => ["overview"].includes(item.key));
    } else if (service === SERVICES.BIOLOGICS) {
      return tabItems.filter(
        (item) =>
          !["vials", "timelines", "emrData", "vitals", "graphs"].includes(
            item.key
          )
      );
    } else {
      return tabItems.filter(
        (item) =>
          !["questionnaires", "feNo", "dashboard", "vials"].includes(item.key)
      );
    }
  };

  return (
    <Row data-testid="patient-view">
      <Col span={24}>
        <ResolveAlert
          form={form}
          handleTextAreaChange={handleTextAreaChange}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          handleDropdownChange={handleDropdownChange}
          cancelModal={cancelModal}
          modalOpen={modalOpen}
          loading={loading}
          selectedReason={selectedReason}
        />
        <Card>
          {tabItems && (
            <Tabs
              type="card"
              defaultActiveKey={activeTab}
              onChange={handleTabChange}
              items={getTabMenus()}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};
export default PatientDetails;
