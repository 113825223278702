import { Table, Tag } from "antd";
import { displayOnlyDate } from "../../Common/Utils";

const History = ({ orderHistory }) => {
  const columns = [
    {
      title: "Devices",
      dataIndex: "devices",
      render: (_, record) =>
        record?.devices ? (
          <>
            {record?.devices?.map((item, index) => {
              return <Tag key={index}>{item}</Tag>;
            })}
          </>
        ) : (
          ""
        ),
    },
    {
      title: "Ordered On",
      dataIndex: "created",
      render: (_, record) => displayOnlyDate(record?.created),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
    },
  ];

  return (
    <Table
      bordered
      rowKey={(record) => record?.created || record?.orderedTime}
      columns={columns}
      dataSource={orderHistory}
      scroll={{ x: "100%" }}
      pagination={false}
    />
  );
};

export default History;
