import { Col, Row, Typography } from "antd";
import { COMMON, HOME } from "../Common/AppContent";
import VideoPlayer from "../Common/VideoPlayer";
const { Title } = Typography;

const ForPatient = ({ videos }) => {
  return (
    <div className="grey-fill">
      <Row className="pd2">
        <Col span={24}>
          <Title level={2} className="text-center fw700">
            <div className="orange">{COMMON.APP_NAME}</div>
            <div className="blue">{HOME.TESTIMONIAL.BLUE_HEADER}</div>
          </Title>
        </Col>
      </Row>
      <div id="vitalDevices">
        <div className="pd2 mb1">
          <VideoPlayer
            link={videos?.home?.links?.devices}
            thumbnailWithIcon={videos?.home?.thumbnails?.withIcon?.devices}
            thumbnailWithoutIcon={
              videos?.home?.thumbnails?.withoutIcon?.devices
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ForPatient;