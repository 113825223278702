import {
  ClockCircleOutlined,
  CloseOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, Table } from "antd";
import { BUTTONS, USER_TYPES } from "../Constants";
import Loader from "../Loader";

const Upcoming = ({
  bookingList,
  userType,
  showRescheduleModal,
  getBookingDetails,
  showCancelConfirm,
  loadingRowId,
  loadingCancelBtn,
  locationType,
}) => {
  const columns = [
    {
      title: "Patient Name",
      dataIndex: "fullName",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Contact Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Scheduled For",
      dataIndex: "bookingDate",
    },
    {
      title: "Return Date",
      dataIndex: "returnDate",
    },
    {
      title: "Slot",
      dataIndex: "slot",
    },
    {
      title: "Type",
      dataIndex: "bookingType",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) =>
        userType === USER_TYPES.PATIENT &&
        record?.bookingType === "FOTONA-NIGHTLASE" ? (
          <div className="flex-center">
            <Button
              size="small"
              className="green-btn mr05"
              onClick={() => getBookingDetails(record)}
            >
              <>
                <EyeOutlined className="mr05" />
                {BUTTONS.VIEW}
              </>
            </Button>
          </div>
        ) : (
          <div className="flex-center">
            <Button
              size="small"
              type="primary"
              className="mr05 blue-btn"
              onClick={() => showRescheduleModal(record)}
            >
              <ClockCircleOutlined /> Reschedule
            </Button>
            <Button
              size="small"
              className="green-btn mr05"
              onClick={() => getBookingDetails(record)}
            >
              <>
                <EyeOutlined className="mr05" />
                {BUTTONS.VIEW}
              </>
            </Button>
            <Button
              size="small"
              onClick={() => showCancelConfirm(record)}
              disabled={loadingCancelBtn && loadingRowId === record.bookingID}
            >
              <Loader
                loading={loadingCancelBtn && loadingRowId === record.bookingID}
                text={
                  <>
                    <CloseOutlined className="mr05" />
                    {BUTTONS.CANCEL}
                  </>
                }
              />
            </Button>
          </div>
        ),
    },
  ];

  return (
    <div data-testid="sleepUpcoming">
      <Table
        bordered
        rowKey={(record) => `${record?.bookingID}-${record?.bookingDate}`}
        columns={
          userType === USER_TYPES.PATIENT
            ? columns.filter(
                (column) =>
                  !["fullName", "phoneNumber"].includes(column.dataIndex)
              )
            : locationType === "fotona"
            ? columns.filter(
                (column) =>
                  !["returnDate", "bookingType"].includes(column.dataIndex)
              )
            : userType === USER_TYPES.CLINIC_ADMIN
            ? columns.filter(
                (column) => !["bookingType"].includes(column.dataIndex)
              )
            : columns
        }
        dataSource={bookingList}
        scroll={{ x: "100%" }}
        pagination={false}
      />
    </div>
  );
};

export default Upcoming;
