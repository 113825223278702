import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Row, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { postApis } from "../../Common/Apis";
import { NOTIFICATIONS } from "../../Common/Constants";
import { providerViewPatientVialsUrl } from "../../Common/Endpoints";
import Popup from "../../Common/Popup";

const Vials = ({ vialDetails, notify, service, token, defaultDoctor }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState();

  const columns = [
    {
      title: "Address",
      dataIndex: "shippingAddress",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
    },
  ];

  const cancelModal = () => {
    setIsModalOpen(false);
  };

  const onDateChange = (date, dateString) => {
    setDate(dateString);
  };

  const updateVials = async () => {
    if (date) {
      await postApis(providerViewPatientVialsUrl, {
        dateOfLastVisit: date,
        token: token,
        service: service,
        doctor: defaultDoctor,
      })
        .then((response) => {
          notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
        });
    } else {
      notify(NOTIFICATIONS.ERROR.KEY, "Please select any date");
    }
  };

  return (
    <>
      <Card className="blue-card">
        <Row gutter={[16, 16]} justify="space-between">
          <Col
            xs={24}
            sm={24}
            md={18}
            lg={18}
            xl={18}
            className="flex-start-center white"
          >
            <b className="mr05">Last Visited On:</b>
            {vialDetails?.lastVisitData?.dateOfLastVisit}
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={3}>
            <Button
              onClick={() => setIsModalOpen(true)}
              icon={<EditOutlined />}
            >
              Edit
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        bordered
        className="mt1"
        rowKey={(record) => new Date(record.created).getTime()}
        columns={columns}
        dataSource={vialDetails?.orders}
        scroll={{ x: "100%" }}
        pagination={false}
      />
      <Popup
        title="Last Visit Date"
        open={isModalOpen}
        cancel={cancelModal}
        footer={null}
        width={500}
      >
        <div className="pd1">
          <DatePicker
            placeholder="MM/DD/YYYY"
            format="MM/DD/YYYY"
            onChange={onDateChange}
            disabledDate={(current) =>
              current && current > moment().endOf("day")
            }
            inputReadOnly
          />
        </div>
        <Row justify={"center"} className="mt1">
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Button type="primary" onClick={() => updateVials()} size="large">
              Submit
            </Button>
          </Col>
        </Row>
      </Popup>
    </>
  );
};

export default Vials;
