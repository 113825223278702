import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { BUTTONS, VALIDATIONS } from "../../Common/Constants";
import Loader from "../../Common/Loader";
import { displayDateTime } from "../../Common/Utils";
const { TextArea } = Input;

const SymptomsForm = ({
  mainItem,
  onSymptomsFinish,
  onSymptomsFinishFailed,
  loading,
}) => {
  return (
    <Card
      title={
        <Row justify={"end"} gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="fs20">
            {mainItem?.service}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="flex-end">
            <span className="mr05">
              <ClockCircleOutlined /> Submitted On:
            </span>
            <span>{displayDateTime(mainItem?.lastUpdated)}</span>
          </Col>
        </Row>
      }
      className="bdr3 box-shadow mt1"
    >
      <p>
        If you are experiencing symptoms from Sublingual Immunotherapy, please
        report it here.
      </p>
      <Form
        name={`${mainItem?.clinic}-${mainItem?.service}-symptoms`}
        initialValues={{
          clinic: mainItem?.clinic,
          service: mainItem?.service,
          type: mainItem?.type,
          subType: mainItem?.subType,
        }}
        size="large"
        onFinish={onSymptomsFinish}
        onFinishFailed={onSymptomsFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      >
        <Form.Item name="clinic" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="service" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="type" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="subType" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={4} placeholder="Symptoms" />
        </Form.Item>
        <Row justify={"center"}>
          <Col xs={12} sm={8} md={8} lg={4} xl={4}>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={loading}
              className="mt1"
            >
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default SymptomsForm;
