import { Button, Col, Flex, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { HOME } from "../Common/AppContent";
import { BUTTONS, ROUTES } from "../Common/Constants";
import ColorWidget from "../Common/ColorWidget";
const { Title } = Typography;

const WhatWeDo = () => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(ROUTES.PUBLIC.WHAT_WE_DO.PATH);
    window.scrollTo(0, 0);
  };

  return (
    <div className="grey-fill pd2">
      <Row>
        <Col span={24}>
          <Title level={2}>
            <Flex wrap="wrap" className="flex-center">
              <span className="blue mr075">{HOME.WHAT_WE_DO.BLUE_HEADER}</span>
              <span className="orange">{HOME.WHAT_WE_DO.ORANGE_HEADER}</span>
            </Flex>
          </Title>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="space-around" className="mt2">
        {HOME.WHAT_WE_DO.CARDS?.map((item, index) => {
          return (
            <ColorWidget
              key={index}
              minHeight={"330px"}
              imgSrc={item?.IMG_SRC}
              title={item?.TITLE}
              description={item?.DESCRIPTION}
              path={item?.PATH}
              textClass={item?.TEXT_CLASS}
              widgetClass={item?.WIDGET_CLASS}
              linkClass={item?.LINK_CLASS}
            />
          );
        })}
      </Row>
      <Row justify={"center"} className="mt2 mb1">
        <Col xs={16} sm={8} md={6} lg={5} xl={5}>
          <Button
            type="primary"
            size="large"
            className="mt1"
            onClick={onClickHandler}
          >
            {BUTTONS.LEARN_MORE}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default WhatWeDo;
