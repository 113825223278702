import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProviderFilters } from "../../../Reducers/filterSlice";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis, postApis } from "../../Common/Apis";
import { NOTIFICATIONS } from "../../Common/Constants";
import {
  clinicAdminAddProviderUrl,
  clinicAdminProvidersUrl,
  clinicAdminVerifyUserUrl,
  defaultPageSize,
} from "../../Common/Endpoints";
import useNotify from "../../Common/Notify";
import {
  getRemoteConfigData,
  processedPhoneNumber,
  tagColors,
} from "../../Common/Utils";
import AddProvider from "./AddProvider";

const Providers = () => {
  const [loading, setLoading] = useState(false);
  const [providerList, setProviderList] = useState();
  const [providers, setProviders] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [searchQuery, setSearchQuery] = useState();
  const [fromPage, setFromPage] = useState(1);
  const [forward, setForward] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [staticDropdownData, setStaticDropdownData] = useState();
  const filters = useSelector((state) => state?.filter?.providers);
  const [paginationDetails, setPaginationDetails] = useState({
    current: 1,
    pageSize: defaultPageSize,
  });
  const { Search } = Input;
  const { notify } = useNotify();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const getProviders = async (reload) => {
    setShowLoader(true);
    let params;
    if (reload) {
      params = {
        forward: forward,
        paginationSize: paginationDetails.pageSize,
      };
    } else {
      params = {
        forward: forward,
        role: filters?.role,
        searchBy: searchQuery,
        paginationSize: paginationDetails.pageSize,
        firstDate: providerList?.firstDate,
        lastDate: providerList?.lastDate,
      };
    }

    if (searchQuery === "") {
      delete params["searchBy"];
    }
    await getApis(clinicAdminProvidersUrl, params)
      .then((response) => {
        setShowLoader(false);
        setProviderList(response?.data);
        setProviders(response?.data?.providers);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getRemoteConfigData("DROPDOWNS").then((result) =>
      setStaticDropdownData(result)
    );
    getProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationDetails]);

  const handleSearch = async (query) => {
    setProviderList();
    setSearchQuery(query);
    setFromPage(1);
    setForward(true);
    setPaginationDetails({
      forward: true,
      current: 1,
      searchBy: query,
      pageSize: paginationDetails.pageSize,
    });
  };

  const handlePageChange = (pagination) => {
    setFromPage(pagination.current);
    setForward(pagination.current > fromPage);
    setPaginationDetails({
      ...paginationDetails,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      sortDirections: ["descend"],
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Contact",
      dataIndex: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Patients",
      dataIndex: "count",
    },
    {
      title: "Programs",
      dataIndex: "programs",
      render: (_, { programs }) => (
        <>
          {programs
            ?.sort((a, b) => a.localeCompare(b))
            .map((item, index) => {
              return (
                <Tag color={tagColors[index % tagColors.length]} key={index}>
                  {item}
                </Tag>
              );
            })}
        </>
      ),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    const phone = await processedPhoneNumber(values);
    const updatedValues = {
      ...values,
      phoneNumber: phone,
    };
    setLoading(true);
    await postApis(clinicAdminVerifyUserUrl, {
      phoneNumber: phone,
      email: values?.email,
    }).then((response) => {
      if (response?.data?.userExists) {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, response?.data?.message);
      } else {
        postApis(clinicAdminAddProviderUrl, updatedValues)
          .then((res) => {
            setLoading(false);
            setIsModalOpen(false);
            getProviders(true);
            notify(NOTIFICATIONS.SUCCESS.KEY, res?.data?.message);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            setLoading(false);
            notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
          });
      }
    });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const handleRoleChange = (value) => {
    setProviderList();
    setFromPage(1);
    setForward(true);
    dispatch(setProviderFilters({ role: value }));
    setPaginationDetails({
      ...paginationDetails,
      current: 1,
      forward: true,
      role: value,
      paginationSize: paginationDetails.pageSize,
    });
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    !showLoader && (
      <div data-testid="clinicAdmin-providers">
        <AddProvider
          form={form}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          loading={loading}
          onFinish={onFinish}
          staticDropdownData={staticDropdownData}
          onFinishFailed={onFinishFailed}
        />
        <Row justify={"space-between"} gutter={[16, 8]}>
          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <Button
              type="primary"
              className="full-width btn-extra-pd fs16"
              onClick={showModal}
            >
              <PlusOutlined /> Add Provider
            </Button>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Select
              title="Filter By Role"
              size="large"
              value={filters?.role}
              placeholder="Select Role"
              options={staticDropdownData?.providers?.roles}
              className="full-width"
              onChange={handleRoleChange}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Search
              allowClear
              value={searchQuery}
              placeholder="Search by First Name"
              onSearch={handleSearch}
              onChange={handleSearchInputChange}
              size="large"
              className="full-width search"
            />
          </Col>
        </Row>
        <Row className="mt1">
          <Col span={24}>
            <Table
              rowKey={(record) => new Date(record.created).getTime()}
              columns={
                filters?.role === "Provider"
                  ? columns
                  : columns.filter((column) => column.dataIndex !== "count")
              }
              dataSource={providers}
              scroll={{ x: "100%" }}
              onChange={handlePageChange}
              pagination={{
                current: paginationDetails.current,
                pageSize: paginationDetails.pageSize,
                total: providerList?.totalProviders,
                showSizeChanger: false,
                showQuickJumper: false,
                hideOnSinglePage: true,
                showTotal: (total, range) => (
                  <div className="mt025">
                    Total <b>{total}</b> Items
                  </div>
                ),
              }}
            />
          </Col>
        </Row>
      </div>
    )
  );
};
export default Providers;
