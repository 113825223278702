import { Col, Flex, Row, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis, postApis } from "../../Common/Apis";
import { NOTIFICATIONS } from "../../Common/Constants";
import { providerVialsUrl } from "../../Common/Endpoints";
import { getRemoteConfigData } from "../../Common/Utils";
import useNotify from "../../Common/Notify";
import { useSelector } from "react-redux";

const Vials = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { showLoader, setShowLoader } = useApiLoader();
  const [staticDropdownData, setStaticDropdownData] = useState();
  const [vials, setVials] = useState();
  const [status, setStatus] = useState();
  const defaultDoctor =
    useSelector((state) => state?.filter?.defaultDoctor) ||
    `${userDetails?.firstName} ${userDetails?.lastName}`;
  const { notify } = useNotify();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Last Visit",
      dataIndex: "dateOfLastVisit",
    },
    {
      title: "Last Order",
      dataIndex: "dateOfLastOrder",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 180,
      render: (text, record) =>
        record.status === "COMPLETED" ? (
          record.status
        ) : (
          <Select
            size="small"
            value={record?.status}
            placeholder="Select Status"
            options={
              record.status === "HOLD"
                ? staticDropdownData?.common?.vialStatus?.filter(
                    (item) => item?.value === "RESOLVED"
                  )
                : record.status === "MIXED"
                ? staticDropdownData?.common?.vialStatus?.filter(
                    (item) => item?.value === "COMPLETED"
                  )
                : staticDropdownData?.common?.vialStatus
            }
            className="full-width"
            onChange={(selectedValue) =>
              onStatusActionChange(selectedValue, record)
            }
          />
        ),
    },
  ];

  const onStatusActionChange = async (value, record) => {
    await postApis(providerVialsUrl, {
      oldStatus: status,
      newStatus: value,
      dateOfLastVisit: record.dateOfLastVisit,
      name: record.name,
      doctor: defaultDoctor,
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (!staticDropdownData) {
      getRemoteConfigData("DROPDOWNS").then((result) => {
        setStaticDropdownData(result);
        setStatus(result?.common?.vialStatus?.[0]?.value);
      });
    }
    if (status) {
      getVails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const getVails = async () => {
    setShowLoader(true);

    await getApis(providerVialsUrl, { status: status, doctor: defaultDoctor })
      .then((response) => {
        setShowLoader(false);
        setVials(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  return (
    !showLoader && (
      <div data-testid="claims">
        <Row justify={"space-between"} gutter={[16, 8]}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="flex-start-center"
          >
            {defaultDoctor && (
              <Flex wrap="wrap" justify={"start"}>
                <div className="bold mr05">Provider: </div>
                <div>{defaultDoctor}</div>
              </Flex>
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Select
              size="large"
              value={status}
              placeholder="Select Status"
              options={staticDropdownData?.common?.vialStatus?.filter(
                (item) => item?.value !== "RESOLVED"
              )}
              className="full-width"
              onChange={handleStatusChange}
            />
          </Col>
        </Row>
        <Row className="mt1">
          <Col span={24}>
            <Table
              rowKey={(record) => new Date(record?.created).getTime()}
              columns={
                status === "HOLD"
                  ? columns
                  : columns?.filter((i) => i.dataIndex !== "reason")
              }
              dataSource={vials}
              scroll={{ x: "100%" }}
              pagination={false}
            />
          </Col>
        </Row>
      </div>
    )
  );
};

export default Vials;
