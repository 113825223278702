import { Card, Col, Flex, Row, Typography } from "antd";
import FIRST from "../../../Assets/images/WhatWeDo/1.png";
import SECOND from "../../../Assets/images/WhatWeDo/2.png";
import ONE from "../../../Assets/images/WhatWeDo/problem1.png";
import TWO from "../../../Assets/images/WhatWeDo/problem2.png";
import THREE from "../../../Assets/images/WhatWeDo/problem3.png";
import BANNER from "../../../Assets/images/WhatWeDo/bg.png";
import "./style.css";
import { useSelector } from "react-redux";
import { WHAT_WE_DO } from "../../Common/AppContent";
import { useEffect, useState } from "react";
import { getRemoteConfigData } from "../../Common/Utils";
import VideoPlayer from "../../Common/VideoPlayer";
import ColorWidget from "../../Common/ColorWidget";
import HeroSection from "../../Common/Layouts/HeroSection";
const { Title } = Typography;

const WhatWeDoPage = () => {
  const app = useSelector((state) => state?.app);
  const isMobile = app?.isMobile;
  const isTablet = app?.isTablet;
  const [videos, setVideos] = useState();

  useEffect(() => {
    getRemoteConfigData("VIDEOS").then((res) => setVideos(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid="whatWeDo">
      <HeroSection
        titleColSpan={isTablet ? 20 : 10}
        descColSpan={20}
        imgSrc={BANNER}
        subTitle={WHAT_WE_DO.SUB_TITLE}
        title={WHAT_WE_DO.TITLE}
        description={WHAT_WE_DO.DESCRIPTION}
      />

      <div className="grey-fill pd2">
        <Row>
          <Col span={24}>
            <Title level={2} className="text-center">
              <div className="orange">{WHAT_WE_DO.TREATMENT.ORANGE_HEADER}</div>
              <div className="blue">{WHAT_WE_DO.TREATMENT.BLUE_HEADER}</div>
            </Title>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify={"space-evenly"} className="mt2 mb1">
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Card className="text-center problem-box">
              <Title level={4} className="light-green header-margin-01">
                {WHAT_WE_DO.TREATMENT.PROBLEMS.HEADER}
              </Title>
              <img src={ONE} alt="1" width="100%" />
              <Title level={5} className="blue">
                {WHAT_WE_DO.TREATMENT.PROBLEMS.ONE.TITLE}
              </Title>
              <div className="blue mt1">
                {WHAT_WE_DO.TREATMENT.PROBLEMS.ONE.DESCRIPTION}
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Card className="text-center problem-box">
              <Title level={4} className="orange header-margin-01">
                {WHAT_WE_DO.TREATMENT.PROBLEMS.HEADER}
              </Title>
              <img src={TWO} alt="2" width="100%" />
              <Title level={5} className="blue">
                {WHAT_WE_DO.TREATMENT.PROBLEMS.TWO.TITLE}
              </Title>
              <div className="blue mt1">
                {WHAT_WE_DO.TREATMENT.PROBLEMS.TWO.DESCRIPTION}
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Card className="text-center problem-box">
              <Title level={4} className="green header-margin-01">
                {WHAT_WE_DO.TREATMENT.PROBLEMS.HEADER}
              </Title>
              <img src={THREE} alt="3" width="100%" />
              <Title level={5} className="blue">
                {WHAT_WE_DO.TREATMENT.PROBLEMS.THREE.TITLE}
              </Title>
              <div className="blue mt1">
                {WHAT_WE_DO.TREATMENT.PROBLEMS.THREE.DESCRIPTION}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="pd2">
        <Title level={2}>
          <Flex wrap="wrap" className="flex-center">
            <span className="orange mr075">
              {WHAT_WE_DO.PATHWAY.ORANGE_HEADER}
            </span>
            <span className="blue">{WHAT_WE_DO.PATHWAY.BLUE_HEADER}</span>
          </Flex>
        </Title>
        <div className="mt3">
          <VideoPlayer
            link={videos?.home?.links?.whatWeDo}
            thumbnailWithIcon={videos?.home?.thumbnails?.withIcon?.whatWeDo}
            thumbnailWithoutIcon={
              videos?.home?.thumbnails?.withoutIcon?.whatWeDo
            }
          />
        </div>
      </div>
      <div className="pd2 watermark-bg">
        <Row>
          <Col span={24} className="mt-home">
            <Title level={2}>
              <Flex wrap="wrap" className="flex-center">
                <span className="blue mr075">
                  {WHAT_WE_DO.OUR_MISSION.BLUE_HEADER}
                </span>
                <span className="orange">
                  {WHAT_WE_DO.OUR_MISSION.ORANGE_HEADER}
                </span>
              </Flex>
            </Title>
          </Col>
        </Row>
        <Row className="flex-center mt1">
          <Col xs={24} sm={24} md={24} lg={20} xl={20}>
            <ul className="fs20">
              <li>
                <div className="paragraph">
                  <b>{WHAT_WE_DO.OUR_MISSION.HEADER_1}</b>
                  {WHAT_WE_DO.OUR_MISSION.DESCRIPTION_1}
                </div>
              </li>
              <li>
                <div className="paragraph mt1">
                  <b>{WHAT_WE_DO.OUR_MISSION.HEADER_2}</b>
                  {WHAT_WE_DO.OUR_MISSION.DESCRIPTION_2}
                </div>
              </li>
              <li>
                <div className="paragraph mt1">
                  <b>{WHAT_WE_DO.OUR_MISSION.HEADER_3}</b>
                  {WHAT_WE_DO.OUR_MISSION.DESCRIPTION_3}
                </div>
              </li>
              <li>
                <div className="paragraph mt1">
                  <b>{WHAT_WE_DO.OUR_MISSION.HEADER_4}</b>
                  {WHAT_WE_DO.OUR_MISSION.DESCRIPTION_4}
                </div>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
      <div className="grey-fill pd2 mb1">
        <Row>
          <Col span={24} className="mt-home">
            <Title level={2} className="text-center">
              <div className="orange">
                {WHAT_WE_DO.TRANSFORMS.ORANGE_HEADER}
              </div>
              <div className="blue fs32">
                {WHAT_WE_DO.TRANSFORMS.BLUE_HEADER}
              </div>
            </Title>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="space-evenly" className="mt2 mb1">
          {WHAT_WE_DO.TRANSFORMS.CARDS?.map((item, index) => {
            return (
              <ColorWidget
                key={index}
                minHeight={"380px"}
                imgSrc={item?.IMG_SRC}
                title={item?.TITLE}
                description={item?.DESCRIPTION}
                path={item?.PATH}
                textClass={item?.TEXT_CLASS}
                widgetClass={item?.WIDGET_CLASS}
                linkClass={item?.LINK_CLASS}
              />
            );
          })}
        </Row>
      </div>
      <div className="pd2">
        <div className="flex-center">
          <Row justify={"space-evenly"} gutter={[16, 16]}>
            <Col xs={24} sm={24} md={10} lg={8} xl={8}>
              <img src={FIRST} alt="what we do" width="100%" />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={10}
              lg={10}
              xl={10}
              className="flex-center"
            >
              <div className="paragraph mt1 fs22">
                {WHAT_WE_DO.INFORMATION.DESCRIPTION_1}
              </div>
            </Col>
          </Row>
        </div>

        <div className="flex-center mt2">
          {isMobile ? (
            <Row justify={"space-evenly"} gutter={[16, 16]}>
              <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                <img
                  src={SECOND}
                  alt="what we do"
                  width="100%"
                  className="right"
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={10}
                lg={10}
                xl={10}
                className="flex-center"
              >
                <div className="paragraph mt1 fs22">
                  {WHAT_WE_DO.INFORMATION.DESCRIPTION_2}
                </div>
              </Col>
            </Row>
          ) : (
            <Row justify={"space-evenly"} gutter={[16, 16]}>
              <Col
                xs={24}
                sm={24}
                md={10}
                lg={10}
                xl={10}
                className="flex-center"
              >
                <div className="paragraph mt1 fs22">
                  {WHAT_WE_DO.INFORMATION.DESCRIPTION_2}
                </div>
              </Col>
              <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                <img
                  src={SECOND}
                  alt="what we do"
                  width="100%"
                  className="right"
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDoPage;
