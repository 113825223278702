import { Button, Col, Empty, Modal, QRCode, Row } from "antd";
import Popup from "../../../Common/Popup";
import html2canvas from "html2canvas";
import { useRef } from "react";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { clinicAdminSleepDeviceDetailsUrl } from "../../../Common/Endpoints";
import { deleteApis } from "../../../Common/Apis";

const ViewDevice = ({ modalOpen, cancelModal, details }) => {
  const qrCodeRefs = useRef([]);
  const { confirm } = Modal;

  const downloadQRCode = (item, index) => {
    html2canvas(qrCodeRefs.current[index]).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `${item}.png`;
      link.click();
    });
  };

  const showDeleteConfirm = (item) => {
    confirm({
      title: "Are you sure?",
      content: "This action will delete the device from this location.",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        deleteDevice(item);
      },
      onCancel() {},
    });
  };

  const deleteDevice = async (device) => {
    await deleteApis(clinicAdminSleepDeviceDetailsUrl, {
      deviceID: device,
      clinicName: details?.clinicName,
    })
      .then(() => window.location.reload())
      .catch(() => {});
  };

  return (
    <Popup
      title={"Devices"}
      open={modalOpen}
      cancel={cancelModal}
      footer={null}
      width={600}
      style={{ top: "8%" }}
    >
      {details?.devices?.length > 0 ? (
        <div data-testid="view-device">
          <p>
            Download the QR code and stick on the devices. If any device is not
            in use delete here.
          </p>
          {details?.devices.map((item, index) => (
            <Row
              key={index}
              gutter={[16, 16]}
              className="grey-fill box-shadow pd1 mt1 flex-center"
            >
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <div
                  ref={(el) => (qrCodeRefs.current[index] = el)}
                  className="box-shadow"
                >
                  <QRCode
                    value={item || "NA"}
                    bgColor="#fff"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </Col>

              <Col
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="text-center"
              >
                <div className="bold blue">{item}</div>
              </Col>
              <Col xs={24} sm={6} md={6} lg={6} xl={6} className="text-center">
                <div>
                  <Button
                    size="small"
                    onClick={() => downloadQRCode(item, index)}
                    className="blue-btn"
                  >
                    <DownloadOutlined /> Download
                  </Button>
                </div>
                <div>
                  <Button
                    size="small"
                    danger
                    onClick={() => showDeleteConfirm(item)}
                    className="mt1"
                  >
                    <DeleteOutlined /> Delete
                  </Button>
                </div>
              </Col>
            </Row>
          ))}
        </div>
      ) : (
        <Empty />
      )}
    </Popup>
  );
};

export default ViewDevice;
