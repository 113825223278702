import { Spin } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setProfileDetails } from "../Reducers/profileSlice";
import { getApis } from "./Common/Apis";
import { ROUTES, SERVICES, USER_TYPES } from "./Common/Constants";
import {
  clinicAdminProfileUrl,
  patientProfileUrl,
  providerProfileUrl,
} from "./Common/Endpoints";
import {
  convertToCamelCase,
  getRemoteConfigData,
  setUserDetails,
} from "./Common/Utils";

const Landing = () => {
  const isLoggedIn = !!localStorage.getItem("authToken");
  const userType =
    useSelector((state) => state?.app?.userType) ||
    localStorage.getItem("userType");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const clinicAdminMenus = JSON.parse(localStorage.getItem("clinicAdminMenus"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromRing = searchParams.get("fromRing");
  const fromBookings = searchParams.get("fromBookings");

  const patientRedirectPage = (data) => {
    if (fromRing) {
      navigate(ROUTES.PATIENT.CONNECTED_DEVICES.PATH);
    } else if (fromBookings) {
      navigate(ROUTES.PATIENT.BOOKINGS.PATH);
    } else {
      if (data?.length > 0) {
        const service =
          data[0]?.service === SERVICES.SLIT
            ? data[0]?.service
            : convertToCamelCase(data[0]?.service);
        navigate(
          `${ROUTES.PATIENT.DASHBOARD.PATH}/${data[0]?.clinic}/${service}`
        );
      } else {
        navigate(ROUTES.PATIENT.PROFILE.PATH);
      }
    }
  };

  const getPatientProfileData = async () => {
    if (!userDetails?.firstName) {
      await getApis(patientProfileUrl)
        .then((response) => {
          setUserDetails({ ...userDetails, ...response?.data });
          dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
          patientRedirectPage(response?.data?.dashboard);
        })
        .catch(() => {});
    } else {
      patientRedirectPage(userDetails?.dashboard);
    }
  };

  const getProviderProfileDetails = async () => {
    if (!userDetails?.firstName) {
      await getApis(providerProfileUrl)
        .then((response) => {
          setUserDetails({ ...userDetails, ...response?.data });
          dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
          navigate(ROUTES.PROVIDER.DASHBOARD.PATH);
        })
        .catch(() => {});
    } else {
      navigate(ROUTES.PROVIDER.DASHBOARD.PATH);
    }
  };

  const getClinicAdminProfileDetails = async () => {
    if (!userDetails?.firstName) {
      await getApis(clinicAdminProfileUrl)
        .then((response) => {
          setUserDetails({ ...userDetails, ...response?.data });
          dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
          if (!clinicAdminMenus) {
            getRemoteConfigData("PROGRAMS").then((result) => {
              const data = result?.filter(
                (item) => item?.clinic?.value === response?.data?.clinic
              );
              localStorage.setItem(
                "clinicAdminMenus",
                JSON.stringify(data?.[0]?.services)
              );
            });
          }
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(() => {});
    } else {
      navigate(ROUTES.CLINIC_ADMIN.DASHBOARD.PATH);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (userType === USER_TYPES.PATIENT) {
        getPatientProfileData();
      } else if (userType === USER_TYPES.PROVIDER) {
        getProviderProfileDetails();
      } else if (userType === USER_TYPES.CLINIC_ADMIN) {
        getClinicAdminProfileDetails();
      }
    } else {
      navigate(ROUTES.DEFAULT.PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spin fullscreen size="large" />;
};

export default Landing;
