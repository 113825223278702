import { Col } from "antd";
import { useEffect, useState } from "react";
import { getRemoteConfigData } from "../../Common/Utils";
import SlitAnswers from "./Slit";
import BiologicsAnswers from "./Biologics";

const Questionnaires = ({
  questionnaireDetails,
  service,
  profileDetails,
  episodeDetails,
  clinic,
}) => {
  const [questionnaires, setQuestionnaires] = useState();

  useEffect(() => {
    getRemoteConfigData("QUESTIONNAIRES").then((result) => {
      setQuestionnaires(result?.[clinic]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col span={24}>
      {service === "SLIT" ? (
        <SlitAnswers
          questionnaireDetails={questionnaireDetails}
          profileDetails={profileDetails}
          episodeDetails={episodeDetails}
          questionnaires={questionnaires}
        />
      ) : (
        <BiologicsAnswers
          questionnaireDetails={questionnaireDetails}
          profileDetails={profileDetails}
          episodeDetails={episodeDetails}
          questionnaires={questionnaires}
        />
      )}
    </Col>
  );
};

export default Questionnaires;
