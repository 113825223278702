import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Layout,
  Menu,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { getOpenKeys, getPageHeader } from "../Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { sidebarItems } from "../MenuData";
import SmallFooter from "./Footers/Small";
import AuthHeader from "./Headers/Auth";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { setDoctors } from "../../../Reducers/filterSlice";
import { providerDoctorsUrl, providerNotificationUrl } from "../Endpoints";
import { getApis } from "../Apis";
import { setNotification } from "../../../Reducers/authSlice";
import { ROUTES, USER_TYPES } from "../Constants";
import { useApiLoader } from "../ApiLoaderContext";

const { Content, Sider } = Layout;
const { Title } = Typography;

const GlobalLoader = () => {
  const { showLoader } = useApiLoader();
  return showLoader ? <Spin className="flex-center mt6" size="large" /> : null;
};

const AuthLayout = (props) => {
  const defaultDoctor = localStorage.getItem("defaultDoctor");
  const userDetails =
    useSelector((state) => state?.profile?.profileDetails) ||
    JSON.parse(localStorage.getItem("userDetails"));
  const location = useLocation();
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSideMenu, setSelectedSideMenu] = useState(location.pathname);
  const doctorsData = useSelector((state) => state?.filter?.doctors);
  const notification = useSelector((state) => state?.auth?.notification);
  const userType = useSelector((state) => state?.app?.userType);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getDoctors = async () => {
    if (Object.entries(doctorsData).length === 0) {
      await getApis(providerDoctorsUrl, {})
        .then((response) => {
          dispatch(setDoctors(response?.data));
        })
        .catch(() => {});
    }
  };

  const getNotifications = async () => {
    await getApis(
      providerNotificationUrl,
      defaultDoctor ? { doctor: defaultDoctor } : {}
    )
      .then((response) => {
        dispatch(setNotification(response?.data));
      })
      .catch(() => {});
  };

  useEffect(() => {
    setSelectedSideMenu(location.pathname);
    if (userType === USER_TYPES.PROVIDER) {
      if (!notification) {
        getNotifications();
      }
      if (userDetails?.role !== "Provider") {
        getDoctors();
        if (!defaultDoctor) {
          setModalOpen(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType, location.pathname]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const handleSideMenuClick = (e) => {
    navigate(e.key);
    setSelectedSideMenu(e.key);
    setIsDrawerOpen(false);
  };

  const onSelectProvider = (value) => {
    localStorage.removeItem("defaultDoctor");
    localStorage.setItem("defaultDoctor", value);
    setModalOpen(false);
    window.location.reload();
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
      data-testid="auth-layout"
    >
      <AuthHeader
        handleSideMenuClick={handleSideMenuClick}
        toggleDrawer={toggleDrawer}
        isDrawerOpen={isDrawerOpen}
        userDetails={userDetails}
        selectedSideMenu={selectedSideMenu}
        pathname={location.pathname}
      />
      <Layout style={{ backgroundColor: "#eee" }}>
        {!isMobile && (
          <Sider
            collapsed={false}
            className="dashboard"
            theme="dark"
            width={220}
          >
            <Menu
              className="mt1 remove-icon"
              theme="dark"
              selectedKeys={[selectedSideMenu]}
              mode="inline"
              items={sidebarItems(notification)}
              onClick={handleSideMenuClick}
              defaultOpenKeys={[getOpenKeys(location.pathname)]}
            />
          </Sider>
        )}
        <Content className="auth-layout">
          <Row justify={"space-between"} gutter={[16, 8]}>
            <Col xs={24} sm={24} md={24} lg={16} xl={18}>
              <Title level={3} style={{ margin: "0rem 0 0 0" }}>
                {getPageHeader(selectedSideMenu)}
              </Title>
            </Col>

            <Col xs={24} sm={24} md={24} lg={8} xl={6}>
              {userType === USER_TYPES.PROVIDER &&
              userDetails?.role !== "Provider" &&
              !selectedSideMenu.includes("/provider/viewPatient/") ? (
                <Button onClick={showModal} block>
                  <UserSwitchOutlined className="mr05" />
                  {defaultDoctor ? "Change a Provider" : "Select a Provider"}
                </Button>
              ) : (
                userType === USER_TYPES.PATIENT &&
                !selectedSideMenu.includes("/patient/programs") && (
                  <Button
                    key="order"
                    block
                    onClick={() => navigate(ROUTES.PATIENT.PROGRAMS.PATH)}
                  >
                    <PlusOutlined className="mr05" /> Explore Programs
                  </Button>
                )
              )}
            </Col>
          </Row>
          <Divider style={{ margin: "0.5rem 0 1.5rem 0" }} />
          <GlobalLoader />
          {props.children}
          <Modal
            title={defaultDoctor ? "Change a Provider" : "Select a Provider"}
            open={modalOpen}
            closable={!!defaultDoctor}
            onCancel={() => setModalOpen(false)}
            footer={null}
            width={400}
            maskClosable={!!defaultDoctor}
            keyboard={!!defaultDoctor}
          >
            <Row>
              <Col span={24}>
                <Select
                  showSearch
                  defaultValue={defaultDoctor ? defaultDoctor : null}
                  placeholder="Select a Provider"
                  size="large"
                  options={doctorsData?.providers?.map((item) => ({
                    value: item,
                    name: item,
                  }))}
                  className="full-width"
                  onChange={onSelectProvider}
                />
              </Col>
            </Row>
          </Modal>
        </Content>
      </Layout>
      <SmallFooter />
    </Layout>
  );
};
export default AuthLayout;
