import { Card, Col, Row } from "antd";
import ONE from "../../../../Assets/images/Dashboard/totalWellness.svg";
import TWO from "../../../../Assets/images/Dashboard/physical.svg";
import THREE from "../../../../Assets/images/Dashboard/cognitive.svg";
import ACHIEVEMENTS from "../../../../Assets/images/Dashboard/achievements.svg";
import UP from "../../../../Assets/images/Dashboard/upArrow.svg";
import DOWN from "../../../../Assets/images/Dashboard/downArrow.svg";
import PERSON from "../../../../Assets/images/Dashboard/person.png";
import Wellness from "./Cards/Wellness";
import { displayDateTime } from "../../../Common/Utils";
import { ClockCircleOutlined } from "@ant-design/icons";

const defaultDailyData = {
  1: {
    title: "Total",
    strokeColor: "#FF7D01",
    value: 0,
    delta: 0,
    imgSrc: ONE,
  },
  2: {
    title: "Physical",
    strokeColor: "#1CB4BF",
    value: 0,
    delta: 0,
    imgSrc: TWO,
  },
  3: {
    title: "Cognitive",
    strokeColor: "#FFCD00",
    value: 0,
    delta: 0,
    imgSrc: THREE,
  },
  4: {
    title: "Achievements",
    strokeColor: "#",
    value: 0,
    delta: 0,
    imgSrc: ACHIEVEMENTS,
  },
};

const HydraData = ({ wellnessDetails }) => {
  return (
    <div>
      <Row justify={"space-evenly"}>
        <Col span={24}>
          <Card className="blue-card">
            <Row justify={"space-between"}>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <div className="fs24 bold"> Monitor Your Daily Activities</div>
                <p>
                  Monitoring daily activity and maintaining good health care
                  practices is crucial for overall well-being.
                </p>
                <div className="bold mt1" title="Last Updated">
                  <span className="mr05">
                    <ClockCircleOutlined />
                  </span>
                  <span>{displayDateTime(wellnessDetails?.lastUpdated)}</span>
                </div>
              </Col>
              <Col xs={0} sm={0} md={3} lg={3} xl={3}>
                <img src={PERSON} alt="banner" width="100%" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[32, 24]} justify="space-between" className="mt2">
        {wellnessDetails?.list?.map((item, index) => {
          return (
            <Wellness
              key={index}
              apiData={item}
              barImage={defaultDailyData[item?.position]?.imgSrc}
              arrowImage={item?.delta > 0 ? UP : DOWN}
            />
          );
        })}
      </Row>
    </div>
  );
};

export default HydraData;
