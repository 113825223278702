import { Card, Col, DatePicker, Modal, Row, Select, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useApiLoader } from "../../../Common/ApiLoaderContext";
import { getApis, postApis } from "../../../Common/Apis";
import {
  bookingsLocationsUrl,
  clinicAdminSleepDevicesListUrl,
  clinicAdminSleepDevicesUrl,
} from "../../../Common/Endpoints";
import SleepDevicesReturn from "./Return";
import SleepDevicesCompleted from "./Completed";
import dayjs from "dayjs";
import SleepDevicesDispense from "./Dispense";
import DispenseModal from "./DispenseModal";
import { NOTIFICATIONS } from "../../../Common/Constants";
import useNotify from "../../../Common/Notify";
import {
  disableWeekends,
  disableWeekendsAndPastDates,
} from "../../../Common/Utils";

const SleepDevices = () => {
  const [activeTab, setActiveTab] = useState("dispense");
  const [locations, setLocations] = useState();
  const [location, setLocation] = useState();
  const [deviceList, setDeviceList] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [dispenseDevicesModal, setDispenseDevicesModal] = useState(false);
  const { showLoader, setShowLoader } = useApiLoader();
  const [devices, setDevices] = useState();
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const { setShowFullLoader } = useApiLoader();
  const { notify } = useNotify();
  const { confirm } = Modal;

  useEffect(() => {
    if (!locations) {
      getLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location) {
      getDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, location, date]);

  const getLocations = async () => {
    await getApis(bookingsLocationsUrl, { type: "sleep" })
      .then((response) => {
        setLocations(response?.data);
        setLocation(response?.data?.[0]?.value);
      })
      .catch(() => {});
  };

  const getDeviceList = async () => {
    await getApis(clinicAdminSleepDevicesListUrl, { location: location })
      .then((response) => {
        setDeviceList(response?.data);
      })
      .catch(() => {});
  };

  const getDevices = async () => {
    setShowLoader(true);

    await getApis(clinicAdminSleepDevicesUrl, {
      type: activeTab,
      location: location,
      date: date,
    })
      .then((response) => {
        setDevices(response?.data);
        setShowLoader(false);
      })
      .catch(() => {
        setDevices();
        setShowLoader(false);
      });
  };

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const handleLocationChange = (value) => {
    setLocation(value);
  };

  const onDateChange = (date, dateString) => {
    setDate(dateString);
  };

  const renderComponent = (compnent) => {
    return (
      <div>
        <Row justify={"space-between"} gutter={[16, 8]}>
          <Col xs={24} sm={6} md={6} lg={2} xl={2} className="bold flex-center">
            Location:
          </Col>
          <Col xs={24} sm={18} md={18} lg={10} xl={10}>
            <Select
              title="Filter By Location"
              size="large"
              value={location}
              placeholder="Select Location"
              options={locations}
              className="full-width"
              onChange={handleLocationChange}
            />
          </Col>
          <Col xs={24} sm={6} md={6} lg={4} xl={3} className="bold flex-center">
            Filter By Date:
          </Col>
          <Col xs={24} sm={18} md={18} lg={6} xl={6}>
            <DatePicker
              onChange={onDateChange}
              allowClear={false}
              size="large"
              disabledDate={
                activeTab === "dispense"
                  ? disableWeekendsAndPastDates
                  : disableWeekends
              }
              defaultValue={date ? dayjs(date) : ""}
            />
          </Col>
        </Row>
        <div className="mt1">{compnent}</div>
      </div>
    );
  };

  const showDispenseDevicesModal = (record) => {
    setSelectedItem(record);
    setDispenseDevicesModal(true);
    getDeviceList();
  };

  const cancelDispenseDevicesModal = () => {
    setSelectedItem();
    setDispenseDevicesModal(false);
    setDeviceList();
  };

  const showReturnConfirm = (record) => {
    confirm({
      title: "Are you sure?",
      content: `This action will update the return status for ${record?.deviceId} device`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        returnDevice(record);
      },
      onCancel() {},
    });
  };

  const returnDevice = async (record) => {
    setShowFullLoader(true);
    await postApis(clinicAdminSleepDevicesUrl, {
      type: activeTab,
      location: location,
      bookingId: record?.bookingId,
      device: record?.deviceId,
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setShowFullLoader(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        setShowFullLoader(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const tabItems = [
    {
      key: "dispense",
      label: "Dispense",
      children: renderComponent(
        <SleepDevicesDispense
          devices={devices}
          showDispenseDevicesModal={showDispenseDevicesModal}
        />
      ),
    },
    {
      key: "return",
      label: "Return",
      children: renderComponent(
        <SleepDevicesReturn
          devices={devices}
          showReturnConfirm={showReturnConfirm}
        />
      ),
    },
    {
      key: "completed",
      label: "Completed",
      children: renderComponent(<SleepDevicesCompleted devices={devices} />),
    },
  ];

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(clinicAdminSleepDevicesUrl, {
      ...values,
      type: activeTab,
      location: location,
      bookingId: selectedItem?.bookingId,
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setLoading(false);
        setDispenseDevicesModal(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    !showLoader && (
      <Card>
        {dispenseDevicesModal && (
          <DispenseModal
            loading={loading}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            selectedItem={selectedItem}
            deviceList={deviceList}
            dispenseDevicesModal={dispenseDevicesModal}
            cancelDispenseDevicesModal={cancelDispenseDevicesModal}
          />
        )}
        <Tabs
          type="card"
          defaultActiveKey={activeTab}
          onChange={onTabChange}
          items={tabItems}
        />
      </Card>
    )
  );
};

export default SleepDevices;
