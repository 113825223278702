import { Button, Card, Col, Flex, Row } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postApis } from "../../Common/Apis";
import {
  BUTTONS,
  FORM_FIELDS,
  NOTIFICATIONS,
  ROUTES,
} from "../../Common/Constants";
import { providerViewPatientUnregisterUrl } from "../../Common/Endpoints";
import useNotify from "../../Common/Notify";
import { displayDateTime, displayOnlyDate } from "../../Common/Utils";
import Unregister from "./Unregister";

const Overview = ({ service, episodeDetails, profileDetails }) => {
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { notify } = useNotify();
  const currentEpisodeDetails =
    episodeDetails?.episodes?.[episodeDetails?.currentEpisode];

  const showModal = () => {
    setModalOpen(true);
  };

  const cancelModal = () => {
    setModalOpen(false);
  };

  const onFinish = async (values) => {
    const formData = {
      ...values,
      service: service,
      token: profileDetails?.token,
    };
    await postApis(providerViewPatientUnregisterUrl, formData)
      .then((response) => {
        setLoading(false);
        setModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          navigate(ROUTES.PROVIDER.ACTIVE_PATIENTS.PATH);
        }, 3000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const renderEpisodeDetails = (label, value) => {
    return (
      <Row className="mt05 grey-fill pd1">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="bold">
          {label}
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="grey">
          {value || value === 0 ? value : "NA"}
        </Col>
      </Row>
    );
  };

  const renderProfileDetails = (label, value) => {
    if (value) {
      return isMobile ? (
        <Row justify={"center"} className="mt1">
          <Col span={24}>
            <Flex wrap="wrap" gap="small" vertical={isMobile ? true : false}>
              <div className="bold">{label}: </div>
              <div>{value}</div>
            </Flex>
          </Col>
        </Row>
      ) : (
        <Row className="mt1">
          <Col xs={12} sm={12} md={12} lg={8} xl={8} className="bold">
            {label}:
          </Col>
          <Col xs={12} sm={12} md={12} lg={16} xl={16}>
            {value}
          </Col>
        </Row>
      );
    }
  };

  return (
    <div>
      <Row gutter={[12, 16]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={15} xl={15}>
          <Card
            title="Profile Details"
            styles={{ body: { height: "350px", overflow: "auto" } }}
          >
            {renderProfileDetails(FORM_FIELDS.GENDER, profileDetails?.gender)}
            {renderProfileDetails(FORM_FIELDS.AGE, profileDetails?.age)}
            {renderProfileDetails(FORM_FIELDS.MRN, profileDetails?.mrn)}
            {renderProfileDetails(
              FORM_FIELDS.SUB_CATEGORY,
              profileDetails?.patientSubType
            )}
            {renderProfileDetails(FORM_FIELDS.EMAIL, profileDetails?.email)}
            {renderProfileDetails(
              FORM_FIELDS.SURGERY_DATE,
              profileDetails?.surgeryDate
            )}
            {renderProfileDetails(
              FORM_FIELDS.PHONE,
              profileDetails?.phoneNumber
            )}
            {renderProfileDetails(
              FORM_FIELDS.CLINIC,
              profileDetails?.clinicName
            )}
            {renderProfileDetails(
              FORM_FIELDS.SURGERY_LOCATION,
              profileDetails?.surgeryLocation
            )}
            {renderProfileDetails(
              FORM_FIELDS.ADDRESS,
              profileDetails?.addressLine1
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={9} xl={9}>
          <Card
            title="Episode Details"
            styles={{ body: { padding: "15px", minHeight: "350px" } }}
          >
            {renderEpisodeDetails(
              "Episode Number",
              episodeDetails?.currentEpisode
            )}
            {renderEpisodeDetails(
              "Started At",
              currentEpisodeDetails?.episodeStartTimestamp
                ? displayOnlyDate(currentEpisodeDetails?.episodeStartTimestamp)
                : null
            )}
            {renderEpisodeDetails(
              "Days not collected",
              currentEpisodeDetails?.totalDaysMissed
            )}
            {renderEpisodeDetails(
              "Total Days",
              currentEpisodeDetails?.totalDaysCollected
            )}
            {renderEpisodeDetails(
              "Last Measured On",
              currentEpisodeDetails?.lastUpdated
                ? displayDateTime(currentEpisodeDetails?.lastUpdated)
                : null
            )}
          </Card>
        </Col>
      </Row>
      <div className="box-shadow pd1 mt1">
        <Row gutter={[16, 16]}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={20}
            xl={20}
            className="flex-start-center"
          >
            If you only want to unregister the patient from the program please
            click the Unregister button.
          </Col>
          <Col xs={24} sm={24} md={24} lg={4} xl={4}>
            <Button
              type="primary"
              htmlType="submit"
              block
              onClick={() => showModal()}
            >
              {BUTTONS.UNREGISTER}
            </Button>
          </Col>
        </Row>
        <Unregister
          modalOpen={modalOpen}
          cancelModal={cancelModal}
          onFinishFailed={onFinishFailed}
          onFinish={onFinish}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Overview;
