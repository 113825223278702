import { Card, Divider, Empty, Result } from "antd";
import { useEffect, useState } from "react";
import { getApis, postApis } from "../../Common/Apis";
import { NOTIFICATIONS, SERVICES } from "../../Common/Constants";
import { patientQuestionnairesUrl } from "../../Common/Endpoints";
import { displayDateTime, getRemoteConfigData } from "../../Common/Utils";
import useNotify from "../../Common/Notify";
import QuestionsForm from "./QuestionsForm";
import SymptomsForm from "./SymptomsForm";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useApiLoader } from "../../Common/ApiLoaderContext";

const Questionnaires = () => {
  const [loadingRowId, setLoadingRowId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [questionnaires, setQuestionnaires] = useState();
  const [apiData, setApiData] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const { notify } = useNotify();

  const getQuestionnaireApiData = async () => {
    setShowLoader(true);
    await getApis(patientQuestionnairesUrl)
      .then((response) => {
        getClinicBasedGroupedData(response?.data);
        getRemoteConfigData("QUESTIONNAIRES").then((result) => {
          setQuestionnaires(result);
          setShowLoader(false);
        });
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getQuestionnaireApiData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const {
      clinic,
      service,
      type,
      subType,
      feet,
      inches,
      lbsWeight,
      ...answers
    } = values;
    setLoadingRowId(subType);

    const data = {
      ...{ clinic, service, type, subType },
      answers,
    };

    await postApis(patientQuestionnairesUrl, data)
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = async () => {
    setLoading(false);
  };

  const onSymptomsFinish = async (values) => {
    setLoading(true);
    const { clinic, service, type, subType } = values;
    const { description } = values;
    setLoadingRowId(subType);

    await postApis(patientQuestionnairesUrl, {
      clinic,
      service,
      type,
      subType,
      answers: { description: description },
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSymptomsFinishFailed = async () => {
    setLoading(false);
  };

  const getClinicBasedGroupedData = (data) => {
    setApiData(
      data?.reduce((acc, item) => {
        if (!acc[item.clinic]) {
          acc[item.clinic] = [];
        }
        acc[item.clinic].push(item);
        return acc;
      }, {})
    );
  };

  return (
    !showLoader && (
      <div data-testid="patient-questionnaires">
        {apiData
          ? Object.keys(apiData)?.map((clinic, fIndex) => (
              <div key={fIndex} className={fIndex > 0 ? "mt2" : ""}>
                <div className={"orange bold fs20"}>
                  {questionnaires?.[clinic]?.label}
                </div>
                <Divider style={{ margin: "0.25rem 0 1.75rem 0" }} />
                {apiData?.[clinic]?.map((mainItem, mainIndex) => {
                  return mainItem?.submitted ? (
                    mainItem?.service !== SERVICES.SLEEP && (
                      <Card key={mainIndex} className="mt1">
                        <Result
                          title={`${mainItem?.service} - ${
                            questionnaires?.[clinic]?.[mainItem?.service]?.[
                              mainItem?.type
                            ]?.[mainItem?.subType]?.label
                          } has already been submitted`}
                          subTitle={
                            <div>
                              <span className="bold mr05">
                                <ClockCircleOutlined /> Last Submitted On:
                              </span>
                              <span>
                                {displayDateTime(mainItem?.lastUpdated)}
                              </span>
                            </div>
                          }
                        />
                      </Card>
                    )
                  ) : (
                    <div key={mainIndex}>
                      {mainItem?.type === "symptoms" ? (
                        <SymptomsForm
                          mainItem={mainItem}
                          onSymptomsFinish={onSymptomsFinish}
                          onSymptomsFinishFailed={onSymptomsFinishFailed}
                          loading={loading}
                        />
                      ) : (
                        <QuestionsForm
                          questionnaires={questionnaires[clinic]}
                          mainItem={mainItem}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          loading={loading}
                          loadingRowId={loadingRowId}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            ))
          : !showLoader && (
              <Card>
                <Empty />
              </Card>
            )}
      </div>
    )
  );
};

export default Questionnaires;
