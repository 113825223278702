import { Button, Card, Col, Empty, Row } from "antd";
import { useEffect, useState } from "react";
import { useApiLoader } from "../../../Common/ApiLoaderContext";
import { getApis } from "../../../Common/Apis";
import { patientWellnessUrl } from "../../../Common/Endpoints";
import CreateQR from "./CreateQR";
import HydraData from "./HydraData";

const Wellness = ({ profileDetails, clinic }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wellnessDetails, setWellnessDetails] = useState();
  const { showLoader, setShowLoader } = useApiLoader();

  const getWellnessData = async () => {
    setShowLoader(true);
    await getApis(patientWellnessUrl, { clinic: clinic })
      .then((response) => {
        setShowLoader(false);
        setWellnessDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getWellnessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {!profileDetails?.hydraDataCollected && (
        <Row className="mb1">
          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <Button type="primary" block onClick={() => showModal()}>
              Get your GT +
            </Button>
          </Col>
        </Row>
      )}

      {wellnessDetails ? (
        <HydraData wellnessDetails={wellnessDetails} />
      ) : (
        !showLoader && (
          <Card>
            <Empty />
          </Card>
        )
      )}

      {isModalOpen && (
        <CreateQR
          profileDetails={profileDetails}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default Wellness;
