import { ShoppingCartOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import VIALS from "../../../Assets/images/Devices/vials.png";
import { BUTTONS } from "../../Common/Constants";
import Loader from "../../Common/Loader";

const Vials = ({ getVialsOrder, loading }) => {
  return (
    <>
      <Card className="blue-card">
        <Row gutter={[16, 16]} justify="space-between">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={20}
            xl={20}
            className="flex-start-center white"
          >
            <Row gutter={[16, 16]} justify="center">
              <Col xs={0} sm={0} md={0} lg={4} xl={4}>
                <img src={VIALS} alt="vials" width="100%" />
              </Col>
              <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                <div className="fs24 bold">Vials</div>
                <ul className="mt05">
                  <li>
                    You can place a new order only 3 months after your previous
                    one.
                  </li>
                  <li>
                    You can place an order once the provider updates your last
                    visit date.
                  </li>
                  <li>
                    You can place an order only if you've had a recent visit
                    with your provider.
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={4} xl={4} className="flex-center">
            <Button
              type="primary"
              disabled={loading}
              onClick={() => getVialsOrder()}
              icon={<ShoppingCartOutlined className="mr05" />}
            >
              <Loader loading={loading} text={BUTTONS.ORDER} />
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Vials;
