import { Button, Col, Divider, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setBookingDetails,
  setMailSent,
} from "../../../../Reducers/bookingsSlice";
import { getApis, postApis } from "../../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, ROUTES } from "../../../Common/Constants";
import {
  patientBookingsSendDocumentUrl,
  patientBookingsSignedStatusUrl,
} from "../../../Common/Endpoints";
import useNotify from "../../../Common/Notify";
import { centalisedSubmitBtn, rowDataWithIcon } from "../../../Common/Utils";
import { displayReviewInfo } from "./Info";
import Sign from "./Sign";

const Review = ({ setCurrentStep }) => {
  const bookings = useSelector((state) => state?.bookings);
  const bookingDetails = bookings?.details;
  const [loading, setLoading] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const dispatch = useDispatch();
  const { notify } = useNotify();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUrlExpired, setIsUrlExpired] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (bookingDetails?.signedStatus === "Sent") {
      showModal();
    }
    if (!bookingDetails) {
      navigate(ROUTES.PATIENT.BOOKINGS.PATH);
    }
    checkExpiration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendMail = async () => {
    await postApis(patientBookingsSendDocumentUrl, {
      ...bookingDetails,
      name: `${userDetails?.firstName} ${userDetails?.lastName}`,
      email: userDetails?.email,
    })
      .then((response) => {
        dispatch(
          setBookingDetails({
            ...bookingDetails,
            signData: response?.data?.signData,
            signedStatus: response?.data?.status,
            sentAt: new Date().getTime(),
          })
        );
        dispatch(setMailSent(response?.data?.status === "Sent"));
        showModal();
      })
      .catch(() => {});
  };

  const checkExpiration = () => {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - bookingDetails?.sentAt;
    const minutesDifference = timeDifference / (1000 * 60);
    if (minutesDifference >= 2) {
      setIsUrlExpired(true);
    } else {
      setIsUrlExpired(false);
    }
  };

  const getSignForm = () => {
    if (isUrlExpired) {
      resendMail();
    } else {
      showModal();
    }
  };

  const onSubmitHandler = async () => {
    setLoading(true);
    checkExpiration();
    await getApis(patientBookingsSignedStatusUrl, {
      bookingDate: bookingDetails?.bookingDate,
      oldBookingDate: bookingDetails?.oldBookingDate,
      requestId: bookingDetails?.signData?.requestID,
    })
      .then((response) => {
        if (response?.data?.signed) {
          setLoading(false);
          setCurrentStep(4);
        } else {
          getSignForm();
        }
        localStorage.setItem("bookingDetails", JSON.stringify(bookingDetails));
      })
      .catch((error) => {
        setLoading(false);
        getSignForm();
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  return (
    <Row data-testid="bookings-review" justify={"center"} className="mt2">
      <Sign
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        signUrl={bookingDetails?.signData?.signUrl}
      />
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <div className="mt2">
          {displayReviewInfo(bookingDetails)?.map((item, index) => {
            return rowDataWithIcon(index, item?.icon, item?.label, item?.value);
          })}
        </div>

        {/* <p className="mt2">
          Kindly provide your card information in the subsequent step as a
          security measure. Your card details will be securely stored and will
          only be charged in the event of a late return or if the device is
          returned damaged.
        </p> */}
        <Divider style={{ margin: "2rem 0 1.5rem 0" }} />
        {centalisedSubmitBtn(loading, BUTTONS.NEXT, onSubmitHandler)}
        <div className="flex-center mt1">
          <Button size="small" onClick={() => setCurrentStep(1)}>
            Go Back
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default Review;
